import React from 'react';
import classNames from 'classnames';
import styles from './icon.css';

export type IconProps = {
    icon: SpriteSymbol;
    title?: string;
    size?: 'S' | 'M' | 'L' | 'XL'; // 12 16 20 32
    className?: string;
};

const Icon = ({ icon, title, size, className }: IconProps) => {
    const iconClass = classNames(styles.root, className, {
        [styles.rootXl]: size === 'XL',
        [styles.rootL]: size === 'L',
        [styles.rootM]: size === 'M',
        [styles.rootS]: size === 'S',
    });

    return (
        <span className={iconClass} title={title}>
            <svg viewBox={icon.viewBox}>
                <use xlinkHref={icon.url} />
            </svg>
        </span>
    );
};

export default React.memo(Icon);
