import React from 'react';
import { RouteProps, Route } from 'react-router-dom';
import createRecoverableLazy from '@/util/recoverable-lazy';

export type ComponentLoader = () => Promise<{
    default: React.ComponentType<any>;
}>;

type Props = Omit<RouteProps, 'component'>;

/**
 * AsyncRoute is a route where the component is loaded using React.lazy and React.Suspense (see https://reactjs.org/docs/code-splitting.html)
 * @param props
 */
const createAsyncRoute = (componentLoader: ComponentLoader) => {
    const Component = createRecoverableLazy(componentLoader);
    return (props: Props) => {
        return <Route {...props} component={Component} />;
    };
};

export default createAsyncRoute;
