import { useReducer } from 'react';
import { Action } from './actions';
import { GlobalState, initialState } from './state';

export const reducer = (state: GlobalState, action: Action): GlobalState => {
    switch (action.type) {
        case 'collapseMenu':
            return {
                ...state,
                menuExpanded: false,
            };
        case 'expandMenu':
            return {
                ...state,
                menuExpanded: true,
            };
        case 'toggleMenu':
            return {
                ...state,
                menuExpanded: !state.menuExpanded,
            };
        case 'hideToggleMenu':
            return {
                ...state,
                menuExpanded: false,
            };
        case 'showNav':
            return {
                ...state,
                navShown: true,
            };
        case 'hideNav':
            return {
                ...state,
                navShown: false,
            };
        case 'toggleNav':
            return {
                ...state,
                navShown: !state.navShown,
            };
        case 'showNotifications':
            return {
                ...state,
                notificationsShown: true,
            };
        case 'hideNotifications':
            return {
                ...state,
                notificationsShown: false,
            };

        case 'showUpgrade':
            return {
                ...state,
                upgradeShown: true,
            };
        case 'hideUpgrade':
            return {
                ...state,
                upgradeShown: false,
            };
        case 'showRecharge':
            return {
                ...state,
                rechargeShown: true,
            };
        case 'hideRecharge':
            return {
                ...state,
                rechargeShown: false,
            };
        case 'toggleNotifications':
            return {
                ...state,
                notificationsShown: !state.notificationsShown,
            };
        case 'setHasUnreadNotifications':
            return {
                ...state,
                hasUnreadNotifications: action.value,
            };
        case 'setSupperUser': {
            return {
                ...state,
                supperUser: true,
            };
        }
        case 'setNormalUser': {
            return {
                ...state,
                supperUser: false,
            };
        }
        case 'setRoomId':
            return {
                ...state,
                roomId: action.value,
            };
        case 'setSearchDeviceId':
            return {
                ...state,
                searchDeviceId: action.value,
            };

        default:
            return state;
    }
};

export default () => {
    return useReducer(reducer, initialState);
};
