import React from 'react';
import classNames from 'classnames';
import styles from './icon-button.css';
import Icon from '@/components/icon';
import { IconProps } from '@/components/icon/icon';

type Props = {
    icon: SpriteSymbol;
    title: string;
    className?: string;
    onClick?: () => void;
    iconSize?: IconProps['size'];
};

const IconButton = (
    { icon, title, className, iconSize = 'L', onClick }: Props,
    ref: React.Ref<HTMLButtonElement>
) => {
    return (
        <button
            ref={ref}
            type="button"
            className={classNames(className, 'animated-border', styles.root)}
            title={title}
            onClick={onClick}
            data-testid="icon-button"
        >
            <Icon size={iconSize} icon={icon} />
            <span className="visually-hidden">{title}</span>
        </button>
    );
};

export default React.forwardRef(IconButton);
