/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useRef, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import usePrevious from '@/hooks/use-previous';
import { ModalProps } from './modal';
import styles from './modal.css';

type ModalContainerProps = {
    modal: React.ReactElement<ModalProps> | null;
    onClickBackdrop: () => void;
};

const useReturnToPreviousFocusedElement = (isActive: boolean) => {
    const returnFocusToElement = useRef<HTMLElement | null>(null);
    const previousActiveValue = usePrevious(isActive);
    useLayoutEffect(() => {
        if (!previousActiveValue && isActive) {
            returnFocusToElement.current = document.activeElement as HTMLElement;
        } else if (previousActiveValue && !isActive) {
            if (returnFocusToElement.current) {
                returnFocusToElement.current.focus();
            }
        }
    }, [isActive, previousActiveValue]);
};

const ModalContainer = ({ modal, onClickBackdrop }: ModalContainerProps) => {
    useReturnToPreviousFocusedElement(!!modal);

    return ReactDOM.createPortal(
        <CSSTransition
            timeout={300}
            in={!!modal}
            classNames={{
                enter: styles.containerEnter,
                enterActive: styles.containerEnterActive,
                exit: styles.containerLeave,
                exitActive: styles.containerLeaveActive,
                enterDone: styles.containerEnterDone,
            }}
        >
            <div className={styles.container}>
                <div
                    data-testid="backdrop"
                    className={styles.backdrop}
                    tabIndex={-1}
                    onClick={onClickBackdrop}
                />
                {modal}
            </div>
        </CSSTransition>,
        document.body
    );
};

export default ModalContainer;
