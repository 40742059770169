import { useRef, useEffect } from 'react';

const useTimeout = (timeout: number, callback?: Function) => {
    const callbackRef = useRef<Function>();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        const timeoutId = window.setTimeout(() => {
            if (callbackRef.current) {
                callbackRef.current();
            }
        }, timeout);
        return () => window.clearTimeout(timeoutId);
    }, [timeout]);
};

export default useTimeout;
