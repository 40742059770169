/**
 * Get errorcode from Grapqhl repsonse
 * @param error
 */

export default function getErrorCode(error: any, errorToText: any) {
    let errorCode = 'UNKNOWN';
    if (Array.isArray(error.graphQLErrors)) {
        error.graphQLErrors.some((graphQLError: any) => {
            if (
                graphQLError.extensions &&
                graphQLError.extensions.code &&
                graphQLError.extensions.code in errorToText
            ) {
                errorCode = graphQLError.extensions.code;
            }
            return false;
        });
    }
    return errorCode;
}
