/**
 * This file contains checks for environments which can NOT be determined at build time.
 * (Based on host or browser environment)
 */

/**
 * The environment on which the app was deployed
 */
export const DEPLOY_ENV = (function determineDeployEnv() {
    if (window.location.hostname.includes('netlify')) {
        return 'preview';
    }

    if (
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1'
    ) {
        return 'development';
    }

    return 'production';
})();

/**
 * Whether we are running in the e2e environment
 */
export const IS_E2E = !!(window as any).Cypress;
