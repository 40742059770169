import { createBrowserHistory } from 'history';

/**
 * Exports a utility which allows you to manipulate the react-router history globally (outside of components)
 */
export default createBrowserHistory({
    getUserConfirmation(message, callback) {
        if (message) {
            /* eslint-disable no-alert */
            const block = window.confirm(message);
            callback(block);
        }
        return true;
    },
});
