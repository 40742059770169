import React from 'react';

/* Allowed toast types */
export type ToastType = 'success' | 'failure' | 'neutral';

/* Structure of toast */
export type ToastMessageData = {
    type: ToastType;
    message: string;
};

export type ToastMessageWithId = {
    id: string;
} & ToastMessageData;

export type ToastContextValue = {
    dismissToast: (id: string) => void;
    clearToasts: () => void;
    triggerToast: (message: ToastMessageData) => void;
};

const ToastContext = React.createContext<ToastContextValue>({
    dismissToast: () => {},
    clearToasts: () => {},
    triggerToast: () => {},
});

export default ToastContext;
