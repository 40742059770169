import React, { useMemo, useState, useCallback } from 'react';
import ToastMessageList from './toast-message-list';
import ToastContext, {
    ToastMessageWithId,
    ToastContextValue,
    ToastMessageData,
} from './toast-context';

type Props = {
    children: React.ReactNode;
    maxItems?: number;
};

const generateToastId = (function generateToastId() {
    let toastId = 1;
    return () => (toastId++).toString();
})();

const ToastProvider = ({ children, maxItems = 5 }: Props) => {
    const [items, setItems] = useState<ToastMessageWithId[]>([]);

    const triggerToast = useCallback(
        (message: ToastMessageData) => {
            setItems(oldItems => {
                const item: ToastMessageWithId = {
                    id: generateToastId(),
                    ...message,
                };
                return [...oldItems.slice(-(maxItems - 1)), item];
            });
        },
        [maxItems]
    );

    const dismissToast = useCallback((id: string) => {
        setItems(oldItems => {
            return oldItems.filter(item => item.id !== id);
        });
    }, []);

    const clearToasts = useCallback(() => {
        setItems([]);
    }, []);

    const contextValue: ToastContextValue = useMemo(() => {
        return {
            triggerToast,
            dismissToast,
            clearToasts,
        };
    }, [clearToasts, dismissToast, triggerToast]);

    return (
        <ToastContext.Provider value={contextValue}>
            {children}
            <ToastMessageList items={items} />
        </ToastContext.Provider>
    );
};

export default ToastProvider;
