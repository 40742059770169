import { compile } from 'path-to-regexp';

export interface DynamicUrl<Params> {
    (params: Params): string;
    pattern: string;
}

export type Url<Params = any> = DynamicUrl<Params> | string;

/**
 * Returns a url which is dynamic (has dynamic parts).
 * For example: /projects/:project/locations/:location
 *
 * Don't forget to pass a correct "generic" to provide type safe usage.
 * For example: dynamicUrl<{ project: string, location: string }>('/projects/:project/locations/:location')
 *
 * @param {String} pattern The pattern to use for this url (see https://github.com/pillarjs/path-to-regexp)
 */
export function dynamicUrl<Params extends object>(
    pattern: string
): DynamicUrl<Params> {
    const toPath = compile<Params>(pattern);
    const fn = (params: Params) => {
        return toPath(params);
    };
    fn.pattern = pattern;
    return fn;
}

/**
 * Simple function to join url parts and make sure that double slashes get normalized
 * @param parts
 */
export function joinUrl(...parts: string[]) {
    return parts.join('/').replace(/\/\//g, '/');
}

function isDynamicUrl(
    url: string | DynamicUrl<object>
): url is DynamicUrl<object> {
    return typeof url === 'function';
}

/**
 * Allows you to use a nested collection of routes which share a common prefix
 * @param {String} prefix
 * @param {*} children
 */
export function nested<NestedUrlMap>(
    prefix: string,
    children: { [key in keyof NestedUrlMap]: NestedUrlMap[key] }
) {
    return Object.keys(children).reduce((acc, key) => {
        const val: Url = (children as any)[key];

        let newVal: Url;
        if (isDynamicUrl(val)) {
            // the value is a DynamicUrl so lets wrap it with a different function which joins the result with the prefix
            newVal = Object.assign(
                (params: any) => joinUrl(prefix, val(params)),
                { pattern: joinUrl(prefix, val.pattern) }
            );
        } else {
            newVal = joinUrl(prefix, val);
        }

        return {
            ...acc,
            [key]: newVal,
        };
    }, {}) as { [key in keyof NestedUrlMap]: NestedUrlMap[key] };
}

/**
 * This type extracts the params from a dynamic url.
 * For example: for dynamicUrl<{ projectName: string, location: string}>('/dashboard/projects/:projectName/:location') it extracts the type: { projectName: string, location: string }
 * */
export type DynamicUrlProps<T> = T extends DynamicUrl<infer P> ? P : never;
