import React, { Suspense } from 'react';

import { hot } from 'react-hot-loader/root';
import '@/components/core-styling/index.global.css';
import { ApolloProvider } from '@apollo/react-common';
import { Router, Route, Switch } from 'react-router-dom';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { useTranslation } from 'react-i18next';
import '@/i18n/config';

import useEventTracker from '@/hooks/use-event-tracker';
import LocaleProvider from '@/components/locale-provider';
import GlobalStateProvider from '@/components/global-state-provider';
import initErrorLogging from '@/util/error-logging';
import setupApolloClient from '@/graphql/setup-apollo-client';
import history from '@/router/history';
import { publicRoutes, authenticatedRoutes } from '@/router/routes';
import { ModalManagerProvider } from '@/components/modal';
import createAsyncRoute from '@/router/async-route';
import createRecoverableLazy from '@/util/recoverable-lazy';
import {
    NotFoundErrorPage,
    FatalErrorPage,
} from '@/components/error-page-boundary';
import ToastProvider from '@/components/toast-provider';
import { ErrorBoundaryWithoutRouter } from '@/components/error-boundary';

setupLogRocketReact(LogRocket);
LogRocket.init('skkjtc/logrocket');

initErrorLogging();

const client = setupApolloClient();

const pageRoutes = Object.entries(authenticatedRoutes)

    /*
        We want to make sure that /projects/location gets matched before /projects.
        So we need to sort the paths by descending length
    */
    .sort(([path1], [path2]) => path2.length - path1.length)

    .map(([path, componentLoaders]) => {
        const componentLoader = componentLoaders.main;
        if (componentLoader) {
            const AsyncRoute = createAsyncRoute(componentLoader);
            return <AsyncRoute key={path} path={path} />;
        }
        return undefined;
    });

const AsyncMainArea = createRecoverableLazy(() =>
    import(/* webpackChunkName: "main-area" */ '@/containers/main-area')
);

const authenticatedRouteComponent = (
    <Route
        path={Object.keys(authenticatedRoutes).concat('/')}
        render={routeProps => {
            return <AsyncMainArea {...routeProps}>{pageRoutes}</AsyncMainArea>;
        }}
    />
);

const publicRouteComponents = Object.entries(publicRoutes).map(
    ([pattern, componentLoader]) => {
        const AsyncRoute = createAsyncRoute(componentLoader);
        return <AsyncRoute key={pattern} exact path={pattern} />;
    }
);

const App = () => {
    const { i18n } = useTranslation();
    useEventTracker({
        enter: {
            event: 'app/started',
        },
    });

    if (navigator.language === 'zh-CN') {
        i18n.changeLanguage('zhCn');
    } else {
        i18n.changeLanguage('enUs');
    }

    return (
        <ErrorBoundaryWithoutRouter fallback={<FatalErrorPage />}>
            <ApolloProvider client={client}>
                <LocaleProvider>
                    <ToastProvider>
                        <ModalManagerProvider>
                            <GlobalStateProvider>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Router history={history}>
                                        <Switch>
                                            {publicRouteComponents}
                                            {authenticatedRouteComponent}
                                            <Route
                                                component={NotFoundErrorPage}
                                            />
                                        </Switch>
                                    </Router>
                                </Suspense>
                            </GlobalStateProvider>
                        </ModalManagerProvider>
                    </ToastProvider>
                </LocaleProvider>
            </ApolloProvider>
        </ErrorBoundaryWithoutRouter>
    );
};

export default hot(App);
