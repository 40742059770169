import React, { useReducer, useCallback, useMemo } from 'react';
import { ModalProps } from './modal';
import ModalContainer from './modal-container';

type ModalManagerContextValue = [boolean, React.Dispatch<Action>];
const mockedContextValue: ModalManagerContextValue = [false, () => {}];

export const ModalManagerContext = React.createContext<
    ModalManagerContextValue
>(mockedContextValue);

type RegisterModal = {
    type: 'registerModal';
    id: string;
    component: React.ReactElement<ModalProps>;
};
type UnregisterModal = { type: 'unregisterModal'; id: string };

type State = Map<string, React.ReactElement<ModalProps>>;
type Action = RegisterModal | UnregisterModal;

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'registerModal':
            return new Map(state).set(action.id, action.component);
        case 'unregisterModal': {
            const copy = new Map(state);
            copy.delete(action.id);
            return copy;
        }
        default:
            return state;
    }
};

const initialModals: State = new Map();

type ModalManagerProviderProps = {
    children: React.ReactNode;
};

export const ModalManagerProvider = ({
    children,
}: ModalManagerProviderProps) => {
    const [modals, dispatch] = useReducer(reducer, initialModals);

    const modalsArray = [...modals.values()];
    const latestModal = modalsArray[modalsArray.length - 1]; // only show the latest modal

    const onClickBackdrop = useCallback(() => {
        modalsArray.forEach(modal => {
            if (modal.props.onHide) {
                modal.props.onHide();
            }
        });
    }, [modalsArray]);

    const contextValue: ModalManagerContextValue = useMemo(() => {
        return [modals.size > 0, dispatch];
    }, [modals.size]);

    return (
        <ModalManagerContext.Provider value={contextValue}>
            {children}
            <ModalContainer
                modal={latestModal}
                onClickBackdrop={onClickBackdrop}
            />
        </ModalManagerContext.Provider>
    );
};
