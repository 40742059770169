import React from 'react';
import classNames from 'classnames';
import useDocumentTitle from '@/hooks/use-document-title';
import Loader from '@/components/loader';
import styles from './page.css';

type Props = {
    header?: React.ReactNode;
    children?: React.ReactNode;
    contentClassName?: string;
    title?: string;
    loading?: boolean;

    /**
     * Set the following prop to true to prevent offsetting of the loader.
     * Offsetting of the loader is used to make sure that the loader stays on the same spot (even when there is a sub-menu, main-menu).
     */
    disableLoaderOffset?: boolean;
};

const Page = ({
    header,
    loading,
    children,
    contentClassName,
    title,
    disableLoaderOffset,
}: Props) => {
    useDocumentTitle(title);

    return (
        <div className={styles.root}>
            {header}
            <div className={classNames(styles.content, contentClassName)}>
                {loading && (
                    <Loader
                        className={classNames(styles.loader, {
                            [styles.loaderOffset]: !disableLoaderOffset,
                        })}
                    />
                )}
                {!loading && children}
            </div>
        </div>
    );
};

export default Page;
