import { useRef, useEffect } from 'react';

const useInitialRender = () => {
    const initial = useRef(true);
    useEffect(() => {
        initial.current = false;
    }, []);
    return initial.current;
};

export default useInitialRender;
