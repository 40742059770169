import React, { useRef } from 'react';
import classNames from 'classnames';
import { useUID } from 'react-uid';
import useLocaleContext from '@/hooks/use-locale-context';
import styles from './modal.css';
import useFocusTrap from '@/hooks/use-focus-trap';
import IconButton from '@/components/icon-button';
import crossIcon from '@/assets/images/icon_boxed_cross_2.5pt.svg';
import { ModalProps } from './modal';
import useScrollLock from '@/hooks/use-scroll-lock';
import { useTranslation } from 'react-i18next';
type ModalContentProps = Pick<
    ModalProps,
    'children' | 'title' | 'onHide' | 'initialFocusRef' | 'className' | 'type'
>;

const ModalContent = ({
    children,
    title,
    initialFocusRef,
    onHide,
    className,
    type,
}: ModalContentProps) => {
    //const { t } = useLocaleContext();
    const { t, i18n } = useTranslation();
    const modalRef = useRef<HTMLElement>(null);
    const titleId = useUID();

    useFocusTrap(modalRef, initialFocusRef);

    useScrollLock(true, {
        enableForDesktop: true,
    });

    return (
        <section
            role="dialog"
            ref={modalRef}
            aria-modal="true"
            aria-labelledby={titleId}
            className={classNames(styles.modal, className, {
                [styles.modalDetailed]: type === 'detailed',
            })}
            data-testid="modal"
        >
            <h2
                id={titleId}
                data-testid="modal-title"
                className={classNames(styles.title, {
                    [styles.titleBottomMargin]: type === undefined,
                    'visually-hidden': type === 'plain',
                })}
            >
                {title}
            </h2>

            <div className={styles.content}>{children}</div>

            {type !== 'plain' && (
                <IconButton
                    className={styles.closeBtn}
                    onClick={onHide}
                    iconSize="L"
                    icon={crossIcon}
                    title={t`Close modal`}
                />
            )}
        </section>
    );
};

export default ModalContent;
