type PartialKeyboardEvent = {
    key: string;
    metaKey?: boolean;
    ctrlKey?: boolean;
    shiftKey?: boolean;
    altKey?: boolean;
};

/**
 * A key checker is a function which checks whether a key is pressed
 */
type KeyChecker = (e: PartialKeyboardEvent) => boolean;

const modifiers: { [key: string]: KeyChecker } = {
    cmd: e => !!e.metaKey,
    ctrl: e => !!e.ctrlKey,
    shift: e => !!e.shiftKey,
    alt: e => !!e.altKey,
};

/**
 * Fixes cross browser issues (Edge *kuch*) with key parameter
 * @param key
 */
const normalizeKey = (key: string) => {
    const mapping: { [key: string]: string } = {
        Win: 'Meta',
        Scroll: 'ScrollLock',
        Spacebar: ' ',

        Down: 'ArrowDown',
        Left: 'ArrowLeft',
        Right: 'ArrowRight',
        Up: 'ArrowUp',

        Del: 'Delete',
        Apps: 'ContextMenu',
        Esc: 'Escape',

        Multiply: '*',
        Add: '+',
        Subtract: '-',
        Decimal: '.',
        Divide: '/',

        Plus: '+',
    };
    return mapping[key] || key;
};

/**
 * Parses a key string to tokens
 * @param str
 */
const keyStringToPatternList = (str: string) => str.split(/\s*\+\s*/);

/**
 * Parses tokens to a list of KeyChecker functions
 * @param tokens
 */
const patternListToCheckList = (tokens: string[]): KeyChecker[] => {
    let simpleKeyCheckCount = 0;

    return tokens.map(key => {
        if (key in modifiers) {
            return modifiers[key];
        }

        simpleKeyCheckCount++;

        if (simpleKeyCheckCount > 1) {
            throw new Error(
                'It is not allowed to have more than one simple keycheck'
            );
        }

        const normalizedKey = normalizeKey(key);

        return (e: PartialKeyboardEvent) => e.key === normalizedKey;
    });
};

const and = (checks: KeyChecker[]): KeyChecker => (e: PartialKeyboardEvent) =>
    checks.every(fn => fn(e));

const or = (checks: KeyChecker[]): KeyChecker => (e: PartialKeyboardEvent) =>
    checks.some(fn => fn(e));

/**
 * Parses a pattern or a combination of patterns to a function which can be used to check if a keyboard event applies to the given keystring
 *
 * A pattern list can be one or multiple patterns.
 *
 * An example:
 * The pattern 'a' matches when the key 'a' is pressed.
 * The pattern ['a', 'b'] matches when either the 'a' or the 'b' is pressed.
 * The pattern ['a+ctrl'] matches when the a AND the modifier 'ctrl' is pressed.
 *
 * @param pattern
 */
export default function parseKeyString(pattern: string | string[]): KeyChecker {
    const patternArray = Array.isArray(pattern) ? pattern : [pattern];

    const checkArray = patternArray.map(s =>
        patternListToCheckList(keyStringToPatternList(s))
    );

    return or(checkArray.map(checks => and(checks)));
}
