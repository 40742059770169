import { useLayoutEffect } from 'react';
import useMediaQuery from '@/hooks/use-media-query';

const mobileSelector = '#app';

type Options = {
    enableForDesktop?: boolean;
};

const defaultOptions: Options = {
    enableForDesktop: false,
};

/**
 * Amazing (such wow) function to prevent scroll in background for modals.
 * Is useful for modals / sidebars on mobile and sidebars on desktop
 * @param enabled
 * @param options
 */
const useScrollLock = (enabled: boolean, options: Options = defaultOptions) => {
    const small = useMediaQuery('small');

    useLayoutEffect(() => {
        // save the scroll value so we can revert it later
        // (or in the case of the desktop version to shift the body)
        const { scrollY } = window;

        let restoreScroll: boolean;
        let el: HTMLElement | undefined;

        // only apply for mobile or desktop when explicitly enabled
        const wasApplied = enabled && (small || options.enableForDesktop);

        if (wasApplied) {
            if (small) {
                el = document.querySelector(mobileSelector) as HTMLElement;
                restoreScroll = false;
                el.style.cssText = `
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                    position: fixed;
                    visibility: hidden;
                    transition: visibility 0s 600ms;
                `;
            } else if (options.enableForDesktop) {
                el = document.body;
                restoreScroll = true;
                el.style.cssText = `
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                    position: fixed;
                    top: -${scrollY}px;
                `;
            }
        }

        return () => {
            if (wasApplied) {
                el!.style.cssText = '';

                if (restoreScroll) {
                    window.scrollTo(0, scrollY);
                }
            }
        };
    }, [small, enabled, options.enableForDesktop]);
};

export default useScrollLock;
