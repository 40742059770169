import { useRef, useEffect } from 'react';

/**
 * This hook returns the previous value or undefined (See the js implementation at: https://reactjs.org/docs/hooks-faq.html)
 *
 * // render
 * const value = usePrevious(1); // undefined
 *
 * // next render
 * const value = usePrevious(2); // 1
 *
 * // next render
 * const value = usePrevious(3) // 2
 *
 * @param value
 */
const usePrevious = <T>(value: T) => {
    const savedValue = useRef<T | undefined>(undefined);
    useEffect(() => {
        savedValue.current = value;
    }, [value]);
    return savedValue.current;
};

export default usePrevious;
