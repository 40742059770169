/* eslint-disable react/button-has-type */

import React from 'react';
import classNames from 'classnames';
import IconDropdown from '@/assets/images/icon_angle-bottom.svg';
import Icon from '@/components/icon';
import Spinner from '@/components/spinner';
import styles from './button.css';

type Props = {
    children: React.ReactNode;
    disabled?: boolean;
    big?: boolean;
    icon?: SpriteSymbol;
    className?: string;
    type?: 'submit' | 'button';
    unstyled?: boolean;
    loading?: boolean;
    withDropdown?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Button = (
    {
        children,
        disabled = false,
        big,
        unstyled,
        loading = false,
        className,
        icon,
        type = 'button',
        withDropdown,
        onClick,
    }: Props,
    ref: React.Ref<HTMLButtonElement>
) => {
    const buttonClass = classNames(styles.root, className, {
        [styles.rootLarge]: big,
        [styles.rootUnstyled]: unstyled,
        'animated-border': unstyled,
        [styles.rootIcon]: icon,
        [styles.rootDropdown]: withDropdown,
        [styles.rootLoading]: loading,
    });

    const content = (
        <>
            {icon && <Icon icon={icon} className={styles.icon} />}
            {children}
            {withDropdown && (
                <Icon icon={IconDropdown} className={styles.icon} />
            )}
        </>
    );

    return (
        <button
            ref={ref}
            data-testid="button"
            onClick={onClick}
            className={buttonClass}
            type={type}
            disabled={disabled || loading}
        >
            {loading && <Spinner show size="M" />}
            {icon || withDropdown ? (
                <div className={styles.rootIconContent}>{content}</div>
            ) : (
                content
            )}
        </button>
    );
};

export default React.forwardRef(Button);
