import { useEffect } from 'react';

const suffix = ' - 便农屹智控';

/**
 * A utility to dynamicly change the document title
 * @param title
 */
const useDocumentTitle = (title?: string) => {
    useEffect(() => {
        if (title) {
            document.title = title + suffix;
        }
    }, [title]);
};

export default useDocumentTitle;
