import React from 'react';

/* extract the type of the first argument of React.lazy */
type PromiseFactory = Parameters<typeof React.lazy>[0];

/**
 * There is a issue with the React.lazy feature where you are unable to retry a failed lazy request (React caches the rejected promise)
 * The following function fixes that by recreating the lazy component when the promise is rejected.
 * @param factory
 */
const createRecoverableLazy = (factory: PromiseFactory) => {
    let LazyComponent: React.ComponentType | undefined;

    return function RecoverableLazy<SomeProps>(props: SomeProps) {
        if (!LazyComponent) {
            LazyComponent = React.lazy(() => {
                return factory().catch(e => {
                    LazyComponent = undefined;
                    throw e;
                });
            });
        }
        return <LazyComponent {...props} />;
    };
};

export default createRecoverableLazy;
