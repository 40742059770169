import ApolloClient from 'apollo-client';
import history from '@/router/history';
import urls from '@/router/urls';
import { LogoutDocument } from './generated';

/**
 * Small utility function to do logout and make sure that all private information is cleared
 * @param {ApolloClient} client
 * @param {boolean} rememberReturnUrl
 */
export default function logout(
    client: ApolloClient<any>,
    rememberReturnUrl = true
) {
    client.resetStore();
    client.mutate({
        mutation: LogoutDocument,
    });

    let state;
    if (rememberReturnUrl) {
        state = {
            from: history.location.pathname,
        };
    }

    history.replace(urls.login, state);
}
