import { useEffect, useMemo, useCallback } from 'react';
import parseKeyString from '@/util/parse-key-string';

type ExtraOptions = {
    ref?: React.RefObject<HTMLElement>;
    ignoreInputEvents?: boolean;
};

/**
 * Adds a global listener that is bound to the window (or optionally an element)
 * @param keys
 * @param onPress
 * @param options
 */
const useKeyListener = (
    keys: string | string[],
    onPress: (e: KeyboardEvent) => void,
    options?: ExtraOptions
) => {
    const checker = useMemo(() => parseKeyString(keys), [keys]);
    const ignoreInputEvents =
        typeof options !== 'undefined' ? options.ignoreInputEvents : true;
    const ref = typeof options !== 'undefined' ? options.ref : undefined;

    const onKeyDown = useCallback(
        (e: Event) => {
            const isFromInputSource = ['INPUT', 'TEXTAREA'].includes(
                (e.target as HTMLElement).tagName
            );

            if (ignoreInputEvents && isFromInputSource) {
                return;
            }

            if (checker(e as KeyboardEvent)) {
                onPress(e as KeyboardEvent);
            }
        },
        [checker, onPress, ignoreInputEvents]
    );

    useEffect(() => {
        let elOrWindow: Window | HTMLElement;

        if (!ref || !ref.current) {
            elOrWindow = window;
        } else {
            elOrWindow = ref.current;
        }

        elOrWindow.addEventListener('keydown', onKeyDown, false);
        return () => {
            elOrWindow.removeEventListener('keydown', onKeyDown, false);
        };
    }, [onKeyDown, ref]);
};

export default useKeyListener;
