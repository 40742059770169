import React, { useEffect, useRef } from 'react';
import useKeyListener from './use-key-listener';
import getFocusableElements from '@/util/get-focusable-elements';

/**
 * Simple focus trap inspired by https://github.com/gdkraus/accessible-modal-dialog/blob/master/modal-window.js
 * @param containerRef
 * @param initialFocusRef
 */
const useFocusTrap = (
    containerRef: React.RefObject<HTMLElement>,
    initialFocusRef?: React.RefObject<HTMLElement>
) => {
    const focusWasInitiallySet = useRef(false);

    useKeyListener(
        'Tab',
        e => {
            const focusableItems = getFocusableElements(containerRef.current!);

            if (!focusableItems.length) {
                return;
            }

            const focusedItem = document.activeElement as HTMLElement;
            const focusedItemIndex = focusableItems.indexOf(focusedItem);

            if (e.shiftKey) {
                if (focusedItemIndex === 0) {
                    focusableItems[focusableItems.length - 1].focus();
                    e.preventDefault();
                }
            } else if (focusedItemIndex === focusableItems.length - 1) {
                focusableItems[0].focus();
                e.preventDefault();
            }
        },
        { ignoreInputEvents: false }
    );

    useEffect(() => {
        if (focusWasInitiallySet.current) {
            return;
        }

        if (initialFocusRef) {
            initialFocusRef.current!.focus();
        } else {
            const [firstfocusableItem] = getFocusableElements(
                containerRef.current!
            );
            if (firstfocusableItem) {
                firstfocusableItem.focus();
            }
        }

        focusWasInitiallySet.current = true;
    }, [initialFocusRef, containerRef]);
};

export default useFocusTrap;
