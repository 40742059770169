/* eslint-disable max-classes-per-file */

/**
 * The types of errors that the error boundary can catch
 * In the future these errors can contain more information
 */

export class ApiError extends Error {}
export class NotFoundError extends Error {}
export class ForbiddenError extends Error {}
