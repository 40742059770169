import { ApolloError } from 'apollo-client';
import { NotFoundError, ApiError, ForbiddenError } from './error-types';

type ErrorMapping = {
    api?: string;
    notFound?: string;
    forbidden?: string;
};

function hasErrorCode(code: string, error?: ApolloError) {
    return (
        error &&
        error.graphQLErrors.some(
            e => e.extensions && e.extensions.code === code
        )
    );
}

/**
 * Takes a apollo error and returns a application error. This error can be thrown in your component to trigger the correct behaviour.
 * @param err
 * @param mapping
 */
export default function mapApolloError(
    err: ApolloError,
    mapping: ErrorMapping
) {
    if (hasErrorCode('404', err)) {
        return new NotFoundError(mapping.notFound);
    }
    if (hasErrorCode('403', err)) {
        return new ForbiddenError(mapping.forbidden);
    }
    return new ApiError(mapping.api);
}
