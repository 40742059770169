import React from 'react';
import Page from '@/components/page';
import { NotFoundError, ApiError, ForbiddenError } from './error-types';
import useLocaleContext from '@/hooks/use-locale-context';
import notFoundImage from '@/assets/images/illustration_404.svg?no-sprite';
import Button from '@/components/button';
import blockerImage from '@/assets/images/icon_inline_blocker_2pt.svg';
import Icon from '@/components/icon';
import styles from './error-page-boundary.css';
import { useTranslation } from 'react-i18next';

type ErrorPageLayoutProps = {
    img?: React.ReactNode;
    children: React.ReactNode;
    header?: React.ReactNode;
};

const ErrorPageLayout = ({ children, img, header }: ErrorPageLayoutProps) => {
    return (
        <Page contentClassName={styles.root} header={header}>
            {img}
            <div className={styles.content}>{children}</div>
        </Page>
    );
};

type ErrorPageProps<ErrorType = {}> = {
    header?: React.ReactNode;
    error?: ErrorType;
};

/**
 * This page is shown when a fatal error occurs.
 */
export const FatalErrorPage = ({ header, error }: ErrorPageProps) => {
    //const { t } = useLocaleContext();
    const { t, i18n } = useTranslation();
    const isWebpackChunkError = error && (error as any).type;

    return (
        <ErrorPageLayout
            header={header}
            img={<Icon icon={blockerImage} className={styles.icon} />}
        >
            {isWebpackChunkError && (
                <p>{t`This page failed to load. This is probably caused by a network error.`}</p>
            )}
            {!isWebpackChunkError && (
                <p>{t`An unexpected error occurred causing CMND.pro to crash. Sorry for the inconvenience.`}</p>
            )}
            <Button onClick={() => window.location.reload()}>
                {t`Reload page`}
            </Button>
        </ErrorPageLayout>
    );
};

/**
 * An error that occurs when a resource was not found by the api.
 * This is probably not an api error because the user might have visited a url for an non existing resource.
 * e.g. visiting /location/1 where location 1 does not exist (anymore).
 * @param param0
 */
export const NotFoundErrorPage = ({
    error,
    header,
}: ErrorPageProps<NotFoundError>) => {
    //const { t } = useLocaleContext();
    const { t, i18n } = useTranslation();
    const message = error
        ? error.message
        : t`Sorry, this page does not seem to exist within BNY-IOT`;
    return (
        <ErrorPageLayout
            header={header}
            img={
                <img
                    src={notFoundImage}
                    className={styles.illustration}
                    alt=""
                />
            }
        >
            {message}
        </ErrorPageLayout>
    );
};

/**
 * An errors that occurs when fetching data from the api. The error message should be in context with the situation in which the error occured.
 * e.g. "We were not able to fetch the locations for project x".
 */
export const ApiErrorPage = ({ error, header }: ErrorPageProps<ApiError>) => {
    return (
        <ErrorPageLayout
            header={header}
            img={<Icon icon={blockerImage} className={styles.icon} />}
        >
            {error ? error.message : ''}
        </ErrorPageLayout>
    );
};

/**
 * This page is shown when the user enters a page for he/she does not have the correct rights.
 */
export const ForbiddenErrorPage = ({
    error,
    header,
}: ErrorPageProps<ForbiddenError>) => {
    return (
        <ErrorPageLayout
            header={header}
            img={<Icon icon={blockerImage} className={styles.icon} />}
        >
            {error ? error.message : ''}
        </ErrorPageLayout>
    );
};
