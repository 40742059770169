import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import iconSpinner from '@/assets/images/icon_boxed_spinner_1.5pt.svg';
import Icon, { IconProps } from '@/components/icon/icon';
import styles from './spinner.css';

type SpinnerProps = {
    show: boolean;
    size?: IconProps['size'];
    className?: string;
};

const Spinner = ({ show, size, className }: SpinnerProps) => {
    return (
        <CSSTransition
            timeout={300}
            in={show}
            unmountOnExit
            mountOnEnter
            classNames={{
                enter: styles.rootEnter,
                enterActive: styles.rootEnterActive,
                exitActive: styles.rootLeaveActive,
            }}
        >
            {size && (
                <Icon
                    icon={iconSpinner}
                    size={size}
                    className={classNames(className, styles.root)}
                />
            )}
        </CSSTransition>
    );
};
export default Spinner;
