import { useEffect } from 'react';
import { trackEvent, EventParams } from '@/util/ga';

type Props = {
    enter?: {
        event: string;
        params?: EventParams;
    };
    exit?: {
        event: string;
        params?: EventParams;
    };
};

/**
 * Small utility to help with tracking appearance (mount) and disappearance (unmount) of components
 * @param param
 */
const useEventTracker = ({ enter, exit }: Props) => {
    useEffect(() => {
        if (enter) {
            trackEvent(enter.event, enter.params);
        }

        return () => {
            if (exit) {
                trackEvent(exit.event, exit.params);
            }
        };
    }, [enter, exit]);
};

export default useEventTracker;
