import React from 'react';
import useGlobalStateReducer from '@/global-state/reducer';

type Props = {
    children: React.ReactNode;
};

type GlobalStateContext = React.Context<
    ReturnType<typeof useGlobalStateReducer>
>;

const GlobalStateProvider = ({ children }: Props) => {
    return (
        <GlobalStateContext.Provider value={useGlobalStateReducer()}>
            {children}
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateProvider;

export const GlobalStateContext = (React.createContext([
    {},
    () => {},
]) as unknown) as GlobalStateContext;
