import React, { useCallback } from 'react';
import ErrorBoundary from '@/components/error-boundary';
import { NotFoundError, ApiError, ForbiddenError } from './error-types';
import {
    NotFoundErrorPage,
    ForbiddenErrorPage,
    ApiErrorPage,
    FatalErrorPage,
} from './error-pages';

type Props = {
    pageHeader: React.ReactNode;
    children: React.ReactNode;
};

const ErrorPageBoundary = ({ children, pageHeader }: Props) => {
    const fallback = useCallback(
        error => {
            if (error instanceof NotFoundError) {
                return <NotFoundErrorPage header={pageHeader} error={error} />;
            }
            if (error instanceof ForbiddenError) {
                return <ForbiddenErrorPage header={pageHeader} error={error} />;
            }
            if (error instanceof ApiError) {
                return <ApiErrorPage header={pageHeader} error={error} />;
            }
            return <FatalErrorPage header={pageHeader} error={error} />;
        },
        [pageHeader]
    );

    return <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>;
};

export default ErrorPageBoundary;
