import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { logError } from '@/util/error-logging';

type ErrorFunc = (error: Error) => React.ReactNode;

type Props = {
    children: React.ReactNode;
    onError?: (error: Error, resetErrors: VoidFunction) => void;
    fallback?: JSX.Element | null | ErrorFunc;
};

type State = {
    error?: Error;
};

const hasAccessToRouter = (props: any): props is RouteComponentProps => {
    return props.history;
};

/**
 * The error boundary catches errors which happen downstream in the component tree
 * see: https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundaryWithoutRouter extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            error: undefined,
        };

        /*
            Always clear the error state when the user leaves to a different route.
            (otherwise nothing will change)
        */
        if (hasAccessToRouter(props)) {
            props.history.listen(() => {
                this.setState(state => {
                    if (state.error) {
                        return {
                            error: undefined,
                        };
                    }
                    return state;
                });
            });
        }
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const { onError } = this.props;

        if (onError) {
            onError(error, () => {
                this.setState({
                    error: undefined,
                });
            });
        }

        logError(error, errorInfo);

        this.setState({
            error,
        });
    }

    public render() {
        const { children, fallback = null } = this.props;
        const { error } = this.state;

        if (error) {
            if (typeof fallback === 'function') {
                return fallback(error);
            }
            return fallback;
        }

        return children;
    }
}

const ErrorBoundaryWithRouter = withRouter(
    (props: Props & RouteComponentProps) => {
        return <ErrorBoundaryWithoutRouter {...props} />;
    }
);

export default ErrorBoundaryWithRouter;
