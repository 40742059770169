import gql from 'graphql-tag.macro';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@/graphql/custom-apollo-hooks';

// THIS IS A GENERATED FILE! DO NOT TOUCH BECAUSE YOUR CHANGES WILL BE UNDONE.
/* eslint-disable */
        
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Date: Date,
};


export type Device = {
  __typename?: 'Device',
  id?: Maybe<Scalars['ID']>,
  deviceId?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  ipAddress?: Maybe<Scalars['String']>,
  macAddress?: Maybe<Scalars['String']>,
  powerStatus?: Maybe<PowerStatus>,
  deviceStatus?: Maybe<DeviceStatus>,
  devicePlatform?: Maybe<DevicePlatform>,
  lastSeen?: Maybe<Scalars['String']>,
  dps?: Maybe<Scalars['String']>,
};

export type DeviceFilterOption = {
  model: Array<Scalars['String']>,
  power: Array<Scalars['String']>,
  software: Array<Scalars['String']>,
};

export type DevicePlatform = {
  __typename?: 'DevicePlatform',
  id: Scalars['ID'],
  model: Model,
  software: Software,
};

export type DevicesFilterResponse = {
  __typename?: 'DevicesFilterResponse',
  devices: Array<Device>,
  totalFilteredDevicesCount: Scalars['Int'],
};

export type DeviceSortByOption = 
  'STATUS' |
  'MODEL' |
  'ID' |
  'IP' |
  'POWER_STATE' |
  'SOFTWARE_VERSION' |
  'NAME' |
  'MAC';

export type DeviceStatus = 
  'NO_ERRORS' |
  'CRITICAL_ERROR_OFFLINE' |
  'CRITICAL_ERROR_TEMPERATURE_OUT_OF_RANGE' |
  'SOFTWARE_OUT_OF_DATE' |
  'UPDATE_SCHEDULED' |
  'UPDATING_DEVICE' |
  'UPDATING_SUCCESSFUL' |
  'UPDATE_FAILED' |
  'GENERIC_ERROR';

export type Location = {
  __typename?: 'Location',
  id: Scalars['ID'],
  name: Scalars['String'],
  deviceCount: Scalars['Int'],
};

export type LocationSummary = {
  __typename?: 'LocationSummary',
  model: Array<LocationSummaryMetricItem>,
  power: Array<LocationSummaryMetricItem>,
  software: Array<LocationSummaryMetricItem>,
};

export type LocationSummaryMetricItem = {
  __typename?: 'LocationSummaryMetricItem',
  /** The label describing the metric item (eg 5.023.1 for software or config-movenpick-aalsmeer-v1.5 for the clone) */
  label: Scalars['String'],
  /** The number of devices for the id */
  value: Scalars['Int'],
};

export type Model = {
  __typename?: 'Model',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type Mutation = {
  __typename?: 'Mutation',
  claimDevice?: Maybe<Device>,
  sendDevicesToReboot: Array<Device>,
  sendDevicesPowerOn: Array<Device>,
  sendDevicesToStandby: Array<Device>,
  updateDeviceName?: Maybe<Device>,
  updateTriggerName?: Maybe<Trigger>,
  updateTriggerCondition?: Maybe<Trigger>,
  updateTriggerAction?: Maybe<Trigger>,
  registerUser: Scalars['Boolean'],
  confirmRegistrationMail: Scalars['Boolean'],
  checkLocation?: Maybe<Scalars['Boolean']>,
  checkEmail?: Maybe<User>,
  checkPhone?: Maybe<User>,
  sendRegistrationMail: Scalars['String'],
  deleteUser: Scalars['Boolean'],
  updateUser: User,
  login?: Maybe<Location>,
  logout?: Maybe<Scalars['Boolean']>,
  sendPasswordRecoveryEmail: Scalars['Boolean'],
  sendChangePassword: Scalars['Boolean'],
  createLocation: Location,
  updateLocation: Location,
  addDeviceToLocation: Scalars['Boolean'],
  removeLocation: Scalars['Boolean'],
  removeMessages: Scalars['Int'],
  removeDevicesFromLocation: Scalars['Boolean'],
  sendSwitchPower: Scalars['Boolean'],
  updateDeviceDp: Scalars['Boolean'],
  updateDeviceDpName: Scalars['Boolean'],
  removeSoftware: Scalars['Boolean'],
  sendDevicesSoftwareUpdate: Array<Device>,
  addDeviceTrigger: Scalars['Boolean'],
  deleteDeviceTrigger: Scalars['Boolean'],
  enableDeviceTrigger: Scalars['Boolean'],
  executeDeviceTrigger: Scalars['Boolean'],
  getSmsCode: Scalars['Boolean'],
  verifySmsCode: Scalars['Boolean'],
  reCharge: ReChargeInfo,
  updateDeviceAmount: Scalars['Boolean'],
};


export type MutationClaimDeviceArgs = {
  qrCode: Scalars['String'],
  locationId: Scalars['ID'],
  update: Scalars['Boolean']
};


export type MutationSendDevicesToRebootArgs = {
  deviceIds: Array<Scalars['ID']>
};


export type MutationSendDevicesPowerOnArgs = {
  deviceIds: Array<Scalars['ID']>
};


export type MutationSendDevicesToStandbyArgs = {
  deviceIds: Array<Scalars['ID']>
};


export type MutationUpdateDeviceNameArgs = {
  input: UpdateDeviceNameInput
};


export type MutationUpdateTriggerNameArgs = {
  input: UpdateTriggerNameInput
};


export type MutationUpdateTriggerConditionArgs = {
  input: UpdateTriggerConditionInput
};


export type MutationUpdateTriggerActionArgs = {
  input: UpdateTriggerActionInput
};


export type MutationRegisterUserArgs = {
  user: UserInputUpdate
};


export type MutationConfirmRegistrationMailArgs = {
  email: Scalars['String'],
  token: Scalars['String']
};


export type MutationCheckLocationArgs = {
  name: Scalars['String']
};


export type MutationCheckEmailArgs = {
  email: Scalars['String']
};


export type MutationCheckPhoneArgs = {
  phone: Scalars['String']
};


export type MutationSendRegistrationMailArgs = {
  email: Scalars['String']
};


export type MutationDeleteUserArgs = {
  email: Scalars['String']
};


export type MutationUpdateUserArgs = {
  user: UserInputUpdate
};


export type MutationLoginArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationSendPasswordRecoveryEmailArgs = {
  email: Scalars['String'],
  language: Scalars['String']
};


export type MutationSendChangePasswordArgs = {
  passwordRecovery?: Maybe<PasswordRecovery>
};


export type MutationCreateLocationArgs = {
  locationName: Scalars['String'],
  user: Scalars['String']
};


export type MutationUpdateLocationArgs = {
  locationName: Scalars['String'],
  locationId: Scalars['ID']
};


export type MutationAddDeviceToLocationArgs = {
  deviceId: Scalars['ID'],
  locationId: Scalars['ID']
};


export type MutationRemoveLocationArgs = {
  locationId: Scalars['ID']
};


export type MutationRemoveMessagesArgs = {
  locationId: Scalars['ID']
};


export type MutationRemoveDevicesFromLocationArgs = {
  deviceIds: Array<Scalars['ID']>
};


export type MutationSendSwitchPowerArgs = {
  deviceId: Scalars['ID'],
  index: Scalars['ID'],
  bPower: Scalars['Boolean']
};


export type MutationUpdateDeviceDpArgs = {
  location: Scalars['String'],
  deviceId: Scalars['ID'],
  parentDpid: Scalars['String'],
  dpid: Scalars['String'],
  dataType: Scalars['String'],
  value: Scalars['String']
};


export type MutationUpdateDeviceDpNameArgs = {
  location: Scalars['String'],
  deviceId: Scalars['ID'],
  parentDpid: Scalars['String'],
  dpid: Scalars['String'],
  name: Scalars['String']
};


export type MutationRemoveSoftwareArgs = {
  softwareid: Scalars['ID']
};


export type MutationSendDevicesSoftwareUpdateArgs = {
  deviceIds: Array<Scalars['ID']>,
  softwareId: Scalars['ID'],
  roomId: Scalars['String']
};


export type MutationAddDeviceTriggerArgs = {
  locationName: Scalars['String'],
  name: Scalars['String'],
  condition: Scalars['String'],
  task: Scalars['String']
};


export type MutationDeleteDeviceTriggerArgs = {
  locationId: Scalars['String'],
  triggerId: Scalars['String']
};


export type MutationEnableDeviceTriggerArgs = {
  locationId: Scalars['String'],
  triggerId: Scalars['String'],
  enable: Scalars['String']
};


export type MutationExecuteDeviceTriggerArgs = {
  locationId: Scalars['String'],
  triggerId: Scalars['String']
};


export type MutationGetSmsCodeArgs = {
  nationCode: Scalars['String'],
  phone: Scalars['String']
};


export type MutationVerifySmsCodeArgs = {
  phone: Scalars['String'],
  code: Scalars['String']
};


export type MutationReChargeArgs = {
  account?: Maybe<Scalars['String']>,
  chargeMoney?: Maybe<Scalars['Int']>
};


export type MutationUpdateDeviceAmountArgs = {
  iccid?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['String']>
};

export type NbiotCharge = {
  __typename?: 'NbiotCharge',
  phone: Scalars['String'],
  iccid: Scalars['String'],
  msisdn: Scalars['String'],
  imsi: Scalars['String'],
  activeDate: Scalars['String'],
  openDate: Scalars['String'],
  amount: Scalars['String'],
  cardStatus: Scalars['String'],
};

export type Notification = {
  __typename?: 'Notification',
  id: Scalars['ID'],
  title: Scalars['String'],
  message: Scalars['String'],
  locationId: Scalars['Int'],
  date: Scalars['String'],
  notificationType: NotificationType,
};

export type NotificationGroup = {
  __typename?: 'NotificationGroup',
  notificationList: Array<Notification>,
};

export type NotificationType = 
  'NETWORK_ERROR' |
  'TEMPERATURE_ERROR' |
  'UPDATE_FAILED' |
  'UPDATE_SUCCESSFUL' |
  'ONLINE' |
  'ERROR_MSG' |
  'INFO_MSG';

export type PasswordRecovery = {
  password: Scalars['String'],
  confirmPassword: Scalars['String'],
  email: Scalars['String'],
  token: Scalars['String'],
};

export type PowerStatus = 
  'ON' |
  'STANDBY' |
  'OFFLINE' |
  'REBOOTING' |
  'STANDBY_TO_ON' |
  'ON_TO_STANDBY';

export type Query = {
  __typename?: 'Query',
  devices: DevicesFilterResponse,
  deviceCount: Scalars['Int'],
  me?: Maybe<User>,
  device: Device,
  trigger: Trigger,
  deviceList: Array<Device>,
  getDevicesByLocationName: Array<Device>,
  getDevicesByLocationId: Array<Device>,
  getOfflineDevices: Array<Device>,
  getUserByEmail: User,
  getUserByPhone: User,
  getUsersWithinLocation: Array<Maybe<User>>,
  locationDeviceCount: Scalars['Int'],
  locations: Array<Location>,
  location?: Maybe<Location>,
  locationSummary: LocationSummary,
  getNotifications: Array<NotificationGroup>,
  models: Array<Model>,
  getSoftwaresForModel: Array<Software>,
  checkDevicesIsSameModel: Scalars['Boolean'],
  getTriggers: Array<Maybe<Trigger>>,
  getNbiotAcount: SimBalanceInfo,
  getDeviceAmount: NbiotCharge,
};


export type QueryDevicesArgs = {
  locationId?: Maybe<Scalars['ID']>,
  pageNo?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>,
  sortBy?: Maybe<DeviceSortByOption>,
  sortOrder?: Maybe<SortOrderOption>,
  filter?: Maybe<DeviceFilterOption>,
  deviceId?: Maybe<Scalars['String']>
};


export type QueryDeviceCountArgs = {
  locationId?: Maybe<Scalars['ID']>
};


export type QueryDeviceArgs = {
  id: Scalars['ID']
};


export type QueryTriggerArgs = {
  id: Scalars['ID']
};


export type QueryDeviceListArgs = {
  deviceIds: Array<Scalars['ID']>
};


export type QueryGetDevicesByLocationNameArgs = {
  locationName: Scalars['String']
};


export type QueryGetDevicesByLocationIdArgs = {
  locationId: Scalars['String']
};


export type QueryGetUserByEmailArgs = {
  email: Scalars['String']
};


export type QueryGetUserByPhoneArgs = {
  phone: Scalars['String']
};


export type QueryGetUsersWithinLocationArgs = {
  locationId: Scalars['ID']
};


export type QueryLocationDeviceCountArgs = {
  locationId: Scalars['ID']
};


export type QueryLocationsArgs = {
  user: Scalars['String'],
  pageNo?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>,
  sortOrder?: Maybe<SortOrderOption>
};


export type QueryLocationArgs = {
  id: Scalars['ID']
};


export type QueryLocationSummaryArgs = {
  locationId: Scalars['ID']
};


export type QueryGetNotificationsArgs = {
  locationId: Scalars['ID'],
  pageNo?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>
};


export type QueryGetSoftwaresForModelArgs = {
  modelId: Scalars['ID']
};


export type QueryCheckDevicesIsSameModelArgs = {
  deviceIds: Array<Scalars['ID']>
};


export type QueryGetTriggersArgs = {
  locationId: Scalars['ID']
};


export type QueryGetNbiotAcountArgs = {
  account?: Maybe<Scalars['String']>
};


export type QueryGetDeviceAmountArgs = {
  iccid?: Maybe<Scalars['String']>
};

export type ReChargeInfo = {
  __typename?: 'ReChargeInfo',
  iccid?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  orderNo?: Maybe<Scalars['String']>,
};

export type SimBalanceInfo = {
  __typename?: 'SimBalanceInfo',
  iccid?: Maybe<Scalars['String']>,
  accountId?: Maybe<Scalars['String']>,
  accountName?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['String']>,
  lateFree?: Maybe<Scalars['String']>,
};

export type Software = {
  __typename?: 'Software',
  id: Scalars['ID'],
  version: Scalars['String'],
  fileName: Scalars['String'],
  isLatest: Scalars['Boolean'],
  model: Model,
};

export type SortOrderOption = 
  'ASC' |
  'DESC';

export type Trigger = {
  __typename?: 'Trigger',
  id: Scalars['ID'],
  name: Scalars['String'],
  enable: Scalars['String'],
  dpcondition: Scalars['String'],
  dpaction: Scalars['String'],
};

export type UpdateDeviceNameInput = {
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type UpdateTriggerActionInput = {
  id: Scalars['ID'],
  dpaction: Scalars['String'],
};

export type UpdateTriggerConditionInput = {
  id: Scalars['ID'],
  dpcondition: Scalars['String'],
};

export type UpdateTriggerNameInput = {
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type User = {
  __typename?: 'User',
  id: Scalars['ID'],
  email: Scalars['String'],
  avatar: Scalars['String'],
  name: Scalars['String'],
  company?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  phone: Scalars['String'],
  superUser: Scalars['Boolean'],
};

export type UserInputUpdate = {
  roomid: Scalars['String'],
  password: Scalars['String'],
  confirmPassword: Scalars['String'],
  name: Scalars['String'],
  company?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  phone: Scalars['String'],
};
export type AddDeviceTriggerMutationVariables = {
  locationName: Scalars['String'],
  name: Scalars['String'],
  condition: Scalars['String'],
  task: Scalars['String']
};


export type AddDeviceTriggerMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'addDeviceTrigger'>);

export type CheckDevicesIsSameModelQueryVariables = {
  deviceIds: Array<Scalars['ID']>
};


export type CheckDevicesIsSameModelQuery = ({ __typename?: 'Query' } & Pick<Query, 'checkDevicesIsSameModel'>);

export type CheckEmailMutationVariables = {
  email: Scalars['String']
};


export type CheckEmailMutation = ({ __typename?: 'Mutation' } & { checkEmail: Maybe<({ __typename?: 'User' } & Pick<User, 'id' | 'name' | 'email' | 'phone' | 'avatar' | 'superUser'>)> });

export type CheckLocationMutationVariables = {
  name: Scalars['String']
};


export type CheckLocationMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'checkLocation'>);

export type CheckPhoneMutationVariables = {
  phone: Scalars['String']
};


export type CheckPhoneMutation = ({ __typename?: 'Mutation' } & { checkPhone: Maybe<({ __typename?: 'User' } & Pick<User, 'id' | 'name' | 'email' | 'phone' | 'avatar' | 'superUser'>)> });

export type ClaimDeviceMutationVariables = {
  qrCode: Scalars['String'],
  locationId: Scalars['ID'],
  update: Scalars['Boolean']
};


export type ClaimDeviceMutation = ({ __typename?: 'Mutation' } & { claimDevice: Maybe<({ __typename?: 'Device' } & DeviceBasicInfoFragment)> });

export type ConfirmRegistrationMailMutationVariables = {
  email: Scalars['String'],
  token: Scalars['String']
};


export type ConfirmRegistrationMailMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'confirmRegistrationMail'>);

export type CreateLocationMutationVariables = {
  locationName: Scalars['String'],
  user: Scalars['String']
};


export type CreateLocationMutation = ({ __typename?: 'Mutation' } & { createLocation: ({ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>) });

export type DeleteDeviceTriggerMutationVariables = {
  locationId: Scalars['String'],
  triggerId: Scalars['String']
};


export type DeleteDeviceTriggerMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'deleteDeviceTrigger'>);

export type DeleteUserMutationVariables = {
  email: Scalars['String']
};


export type DeleteUserMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'deleteUser'>);

export type EnableDeviceTriggerMutationVariables = {
  locationId: Scalars['String'],
  triggerId: Scalars['String'],
  enable: Scalars['String']
};


export type EnableDeviceTriggerMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'enableDeviceTrigger'>);

export type ExecuteDeviceTriggerMutationVariables = {
  locationId: Scalars['String'],
  triggerId: Scalars['String']
};


export type ExecuteDeviceTriggerMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'executeDeviceTrigger'>);

export type DeviceBasicInfoFragment = ({ __typename?: 'Device' } & Pick<Device, 'id' | 'deviceId' | 'name' | 'ipAddress' | 'macAddress' | 'powerStatus' | 'deviceStatus' | 'lastSeen' | 'dps'> & { devicePlatform: Maybe<({ __typename?: 'DevicePlatform' } & Pick<DevicePlatform, 'id'> & { model: ({ __typename?: 'Model' } & Pick<Model, 'id' | 'name'>), software: ({ __typename?: 'Software' } & Pick<Software, 'id' | 'version' | 'fileName' | 'isLatest'> & { model: ({ __typename?: 'Model' } & Pick<Model, 'id' | 'name'>) }) })> });

export type UserBasicInfoFragment = ({ __typename?: 'User' } & Pick<User, 'id' | 'name' | 'email' | 'phone' | 'avatar' | 'superUser'>);

export type GetDeviceAmountQueryVariables = {
  iccid?: Maybe<Scalars['String']>
};


export type GetDeviceAmountQuery = ({ __typename?: 'Query' } & { getDeviceAmount: ({ __typename?: 'NbiotCharge' } & Pick<NbiotCharge, 'phone' | 'iccid' | 'msisdn' | 'imsi' | 'activeDate' | 'openDate' | 'amount' | 'cardStatus'>) });

export type GetDeviceListQueryVariables = {
  deviceIds: Array<Scalars['ID']>
};


export type GetDeviceListQuery = ({ __typename?: 'Query' } & { deviceList: Array<({ __typename?: 'Device' } & Pick<Device, 'id' | 'deviceId' | 'deviceStatus' | 'name'> & { devicePlatform: Maybe<({ __typename?: 'DevicePlatform' } & Pick<DevicePlatform, 'id'> & { software: ({ __typename?: 'Software' } & Pick<Software, 'id' | 'version' | 'fileName' | 'isLatest'> & { model: ({ __typename?: 'Model' } & Pick<Model, 'id' | 'name'>) }), model: ({ __typename?: 'Model' } & Pick<Model, 'id' | 'name'>) })> })> });

export type GetDeviceQueryVariables = {
  id: Scalars['ID']
};


export type GetDeviceQuery = ({ __typename?: 'Query' } & { device: ({ __typename?: 'Device' } & DeviceBasicInfoFragment) });

export type GetDevicesByLocationIdQueryVariables = {
  locationId: Scalars['String']
};


export type GetDevicesByLocationIdQuery = ({ __typename?: 'Query' } & { getDevicesByLocationId: Array<({ __typename?: 'Device' } & DeviceBasicInfoFragment)> });

export type GetDevicesByLocationNameQueryVariables = {
  locationName: Scalars['String']
};


export type GetDevicesByLocationNameQuery = ({ __typename?: 'Query' } & { getDevicesByLocationName: Array<({ __typename?: 'Device' } & DeviceBasicInfoFragment)> });

export type GetDevicesQueryVariables = {
  locationId: Scalars['ID'],
  sort?: Maybe<DeviceSortByOption>,
  sortOrder?: Maybe<SortOrderOption>,
  pageNo?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>,
  filter?: Maybe<DeviceFilterOption>,
  deviceId?: Maybe<Scalars['String']>
};


export type GetDevicesQuery = ({ __typename?: 'Query' } & { devices: ({ __typename?: 'DevicesFilterResponse' } & Pick<DevicesFilterResponse, 'totalFilteredDevicesCount'> & { devices: Array<({ __typename?: 'Device' } & DeviceBasicInfoFragment)> }) });

export type GetLocationSummaryQueryVariables = {
  locationId: Scalars['ID']
};


export type GetLocationSummaryQuery = ({ __typename?: 'Query' } & { locationSummary: ({ __typename?: 'LocationSummary' } & { model: Array<({ __typename?: 'LocationSummaryMetricItem' } & Pick<LocationSummaryMetricItem, 'label' | 'value'>)>, power: Array<({ __typename?: 'LocationSummaryMetricItem' } & Pick<LocationSummaryMetricItem, 'label' | 'value'>)>, software: Array<({ __typename?: 'LocationSummaryMetricItem' } & Pick<LocationSummaryMetricItem, 'label' | 'value'>)> }) });

export type GetLocationQueryVariables = {
  id: Scalars['ID']
};


export type GetLocationQuery = ({ __typename?: 'Query' } & { location: Maybe<({ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>)> });

export type GetLocationsQueryVariables = {
  user: Scalars['String'],
  pageNo?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>
};


export type GetLocationsQuery = ({ __typename?: 'Query' } & { locations: Array<({ __typename?: 'Location' } & Pick<Location, 'id' | 'name' | 'deviceCount'>)> });

export type GetModelsQueryVariables = {};


export type GetModelsQuery = ({ __typename?: 'Query' } & { models: Array<({ __typename?: 'Model' } & Pick<Model, 'id' | 'name'>)> });

export type GetNbiotAcountQueryVariables = {
  account?: Maybe<Scalars['String']>
};


export type GetNbiotAcountQuery = ({ __typename?: 'Query' } & { getNbiotAcount: ({ __typename?: 'SimBalanceInfo' } & Pick<SimBalanceInfo, 'accountId' | 'accountName' | 'amount' | 'lateFree'>) });

export type GetNotificationsQueryVariables = {
  locationId: Scalars['ID'],
  pageNo?: Maybe<Scalars['Int']>,
  pageSize?: Maybe<Scalars['Int']>
};


export type GetNotificationsQuery = ({ __typename?: 'Query' } & { getNotifications: Array<({ __typename?: 'NotificationGroup' } & { notificationList: Array<({ __typename?: 'Notification' } & Pick<Notification, 'id' | 'title' | 'message' | 'locationId' | 'date' | 'notificationType'>)> })> });

export type GetOfflineDevicesQueryVariables = {};


export type GetOfflineDevicesQuery = ({ __typename?: 'Query' } & { getOfflineDevices: Array<({ __typename?: 'Device' } & Pick<Device, 'id' | 'deviceStatus' | 'lastSeen' | 'name'>)> });

export type GetSmsCodeMutationVariables = {
  nationCode: Scalars['String'],
  phone: Scalars['String']
};


export type GetSmsCodeMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'getSmsCode'>);

export type GetSoftwaresForModelQueryVariables = {
  modelId: Scalars['ID']
};


export type GetSoftwaresForModelQuery = ({ __typename?: 'Query' } & { getSoftwaresForModel: Array<({ __typename?: 'Software' } & Pick<Software, 'id' | 'version' | 'fileName' | 'isLatest'> & { model: ({ __typename?: 'Model' } & Pick<Model, 'id' | 'name'>) })> });

export type GetTriggerQueryVariables = {
  id: Scalars['ID']
};


export type GetTriggerQuery = ({ __typename?: 'Query' } & { trigger: ({ __typename?: 'Trigger' } & Pick<Trigger, 'id' | 'name' | 'enable' | 'dpcondition' | 'dpaction'>) });

export type GetTriggersQueryVariables = {
  locationId: Scalars['ID']
};


export type GetTriggersQuery = ({ __typename?: 'Query' } & { getTriggers: Array<Maybe<({ __typename?: 'Trigger' } & Pick<Trigger, 'id' | 'name' | 'enable' | 'dpcondition' | 'dpaction'>)>> });

export type GetUserByEmailQueryVariables = {
  email: Scalars['String']
};


export type GetUserByEmailQuery = ({ __typename?: 'Query' } & { getUserByEmail: ({ __typename?: 'User' } & Pick<User, 'id' | 'name' | 'email' | 'phone' | 'avatar' | 'superUser'>) });

export type GetUserByPhoneQueryVariables = {
  phone: Scalars['String']
};


export type GetUserByPhoneQuery = ({ __typename?: 'Query' } & { getUserByPhone: ({ __typename?: 'User' } & Pick<User, 'id' | 'name' | 'email' | 'phone' | 'avatar' | 'superUser'>) });

export type GetUserQueryVariables = {};


export type GetUserQuery = ({ __typename?: 'Query' } & { me: Maybe<({ __typename?: 'User' } & Pick<User, 'id' | 'name' | 'email' | 'phone' | 'avatar' | 'superUser'>)> });

export type GetUsersByLocationQueryVariables = {
  locationId: Scalars['ID']
};


export type GetUsersByLocationQuery = ({ __typename?: 'Query' } & { getUsersWithinLocation: Array<Maybe<({ __typename?: 'User' } & UserBasicInfoFragment)>> });

export type LoginMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type LoginMutation = ({ __typename?: 'Mutation' } & { login: Maybe<({ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>)> });

export type LogoutMutationVariables = {};


export type LogoutMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'logout'>);

export type ReChargeMutationVariables = {
  account?: Maybe<Scalars['String']>,
  chargeMoney?: Maybe<Scalars['Int']>
};


export type ReChargeMutation = ({ __typename?: 'Mutation' } & { reCharge: ({ __typename?: 'ReChargeInfo' } & Pick<ReChargeInfo, 'iccid' | 'url' | 'orderNo'>) });

export type RegisterUserMutationVariables = {
  user: UserInputUpdate
};


export type RegisterUserMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'registerUser'>);

export type RemoveDevicesFromLocationMutationVariables = {
  deviceIds: Array<Scalars['ID']>
};


export type RemoveDevicesFromLocationMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'removeDevicesFromLocation'>);

export type RemoveLocationMutationVariables = {
  locationId: Scalars['ID']
};


export type RemoveLocationMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'removeLocation'>);

export type RemoveMessagesMutationVariables = {
  locationId: Scalars['ID']
};


export type RemoveMessagesMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'removeMessages'>);

export type RemoveSoftwareMutationVariables = {
  softwareid: Scalars['ID']
};


export type RemoveSoftwareMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'removeSoftware'>);

export type SendDevicesPowerOnMutationVariables = {
  deviceIds: Array<Scalars['ID']>
};


export type SendDevicesPowerOnMutation = ({ __typename?: 'Mutation' } & { sendDevicesPowerOn: Array<({ __typename?: 'Device' } & Pick<Device, 'id' | 'powerStatus'>)> });

export type SendDevicesSoftwareUpdateMutationVariables = {
  deviceIds: Array<Scalars['ID']>,
  softwareId: Scalars['ID'],
  roomId: Scalars['String']
};


export type SendDevicesSoftwareUpdateMutation = ({ __typename?: 'Mutation' } & { sendDevicesSoftwareUpdate: Array<({ __typename?: 'Device' } & DeviceBasicInfoFragment)> });

export type SendDevicesToRebootMutationVariables = {
  deviceIds: Array<Scalars['ID']>
};


export type SendDevicesToRebootMutation = ({ __typename?: 'Mutation' } & { sendDevicesToReboot: Array<({ __typename?: 'Device' } & Pick<Device, 'id' | 'powerStatus'>)> });

export type SendDevicesToStandbyMutationVariables = {
  deviceIds: Array<Scalars['ID']>
};


export type SendDevicesToStandbyMutation = ({ __typename?: 'Mutation' } & { sendDevicesToStandby: Array<({ __typename?: 'Device' } & Pick<Device, 'id' | 'powerStatus'>)> });

export type SendSwitchPowerMutationVariables = {
  deviceId: Scalars['ID'],
  index: Scalars['ID'],
  bPower: Scalars['Boolean']
};


export type SendSwitchPowerMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'sendSwitchPower'>);

export type SendChangePasswordMutationVariables = {
  passwordRecovery: PasswordRecovery
};


export type SendChangePasswordMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'sendChangePassword'>);

export type SendPasswordRecoveryEmailMutationVariables = {
  email: Scalars['String'],
  language: Scalars['String']
};


export type SendPasswordRecoveryEmailMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'sendPasswordRecoveryEmail'>);

export type SendRegistrationMailMutationVariables = {
  email: Scalars['String']
};


export type SendRegistrationMailMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'sendRegistrationMail'>);

export type UpdateDeviceAmountMutationVariables = {
  iccid?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['String']>
};


export type UpdateDeviceAmountMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'updateDeviceAmount'>);

export type UpdateDeviceDpNameMutationVariables = {
  location: Scalars['String'],
  deviceId: Scalars['ID'],
  parentDpid: Scalars['String'],
  dpid: Scalars['String'],
  name: Scalars['String']
};


export type UpdateDeviceDpNameMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'updateDeviceDpName'>);

export type UpdateDeviceDpMutationVariables = {
  location: Scalars['String'],
  deviceId: Scalars['ID'],
  parentDpid: Scalars['String'],
  dpid: Scalars['String'],
  dataType: Scalars['String'],
  value: Scalars['String']
};


export type UpdateDeviceDpMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'updateDeviceDp'>);

export type UpdateDeviceNameMutationVariables = {
  input: UpdateDeviceNameInput
};


export type UpdateDeviceNameMutation = ({ __typename?: 'Mutation' } & { updateDeviceName: Maybe<({ __typename?: 'Device' } & DeviceBasicInfoFragment)> });

export type UpdateLocationMutationVariables = {
  locationName: Scalars['String'],
  locationId: Scalars['ID']
};


export type UpdateLocationMutation = ({ __typename?: 'Mutation' } & { updateLocation: ({ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>) });

export type UpdateTriggerActionMutationVariables = {
  input: UpdateTriggerActionInput
};


export type UpdateTriggerActionMutation = ({ __typename?: 'Mutation' } & { updateTriggerAction: Maybe<({ __typename?: 'Trigger' } & Pick<Trigger, 'id' | 'name' | 'enable' | 'dpcondition' | 'dpaction'>)> });

export type UpdateTriggerConditionMutationVariables = {
  input: UpdateTriggerConditionInput
};


export type UpdateTriggerConditionMutation = ({ __typename?: 'Mutation' } & { updateTriggerCondition: Maybe<({ __typename?: 'Trigger' } & Pick<Trigger, 'id' | 'name' | 'enable' | 'dpcondition' | 'dpaction'>)> });

export type UpdateTriggerNameMutationVariables = {
  input: UpdateTriggerNameInput
};


export type UpdateTriggerNameMutation = ({ __typename?: 'Mutation' } & { updateTriggerName: Maybe<({ __typename?: 'Trigger' } & Pick<Trigger, 'id' | 'name' | 'enable' | 'dpcondition' | 'dpaction'>)> });

export type VerifySmsCodeMutationVariables = {
  phone: Scalars['String'],
  code: Scalars['String']
};


export type VerifySmsCodeMutation = ({ __typename?: 'Mutation' } & Pick<Mutation, 'verifySmsCode'>);
export const DeviceBasicInfoFragmentDoc = gql`
    fragment deviceBasicInfo on Device {
  id
  deviceId
  name
  ipAddress
  macAddress
  powerStatus
  deviceStatus
  devicePlatform {
    id
    model {
      id
      name
    }
    software {
      id
      version
      fileName
      isLatest
      model {
        id
        name
      }
    }
  }
  lastSeen
  dps
}
    `;
export const UserBasicInfoFragmentDoc = gql`
    fragment userBasicInfo on User {
  id
  name
  email
  phone
  avatar
  superUser
}
    `;
export const AddDeviceTriggerDocument = gql`
    mutation AddDeviceTrigger($locationName: String!, $name: String!, $condition: String!, $task: String!) {
  addDeviceTrigger(locationName: $locationName, name: $name, condition: $condition, task: $task)
}
    `;
export type AddDeviceTriggerMutationFn = ApolloReactCommon.MutationFunction<AddDeviceTriggerMutation, AddDeviceTriggerMutationVariables>;

    export function useAddDeviceTriggerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDeviceTriggerMutation, AddDeviceTriggerMutationVariables>) {
      return ApolloReactHooks.useMutation<AddDeviceTriggerMutation, AddDeviceTriggerMutationVariables>(AddDeviceTriggerDocument, baseOptions);
    };
export type AddDeviceTriggerMutationHookResult = ReturnType<typeof useAddDeviceTriggerMutation>;
export type AddDeviceTriggerMutationResult = ApolloReactCommon.MutationResult<AddDeviceTriggerMutation>;
export type AddDeviceTriggerMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDeviceTriggerMutation, AddDeviceTriggerMutationVariables>;
export const CheckDevicesIsSameModelDocument = gql`
    query CheckDevicesIsSameModel($deviceIds: [ID!]!) {
  checkDevicesIsSameModel(deviceIds: $deviceIds)
}
    `;

    export function useCheckDevicesIsSameModelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckDevicesIsSameModelQuery, CheckDevicesIsSameModelQueryVariables>) {
      return ApolloReactHooks.useQuery<CheckDevicesIsSameModelQuery, CheckDevicesIsSameModelQueryVariables>(CheckDevicesIsSameModelDocument, baseOptions);
    };
export type CheckDevicesIsSameModelQueryHookResult = ReturnType<typeof useCheckDevicesIsSameModelQuery>;
export type CheckDevicesIsSameModelQueryResult = ApolloReactCommon.QueryResult<CheckDevicesIsSameModelQuery, CheckDevicesIsSameModelQueryVariables>;
export const CheckEmailDocument = gql`
    mutation checkEmail($email: String!) {
  checkEmail(email: $email) {
    id
    name
    email
    phone
    avatar
    superUser
  }
}
    `;
export type CheckEmailMutationFn = ApolloReactCommon.MutationFunction<CheckEmailMutation, CheckEmailMutationVariables>;

    export function useCheckEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckEmailMutation, CheckEmailMutationVariables>) {
      return ApolloReactHooks.useMutation<CheckEmailMutation, CheckEmailMutationVariables>(CheckEmailDocument, baseOptions);
    };
export type CheckEmailMutationHookResult = ReturnType<typeof useCheckEmailMutation>;
export type CheckEmailMutationResult = ApolloReactCommon.MutationResult<CheckEmailMutation>;
export type CheckEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckEmailMutation, CheckEmailMutationVariables>;
export const CheckLocationDocument = gql`
    mutation checkLocation($name: String!) {
  checkLocation(name: $name)
}
    `;
export type CheckLocationMutationFn = ApolloReactCommon.MutationFunction<CheckLocationMutation, CheckLocationMutationVariables>;

    export function useCheckLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckLocationMutation, CheckLocationMutationVariables>) {
      return ApolloReactHooks.useMutation<CheckLocationMutation, CheckLocationMutationVariables>(CheckLocationDocument, baseOptions);
    };
export type CheckLocationMutationHookResult = ReturnType<typeof useCheckLocationMutation>;
export type CheckLocationMutationResult = ApolloReactCommon.MutationResult<CheckLocationMutation>;
export type CheckLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckLocationMutation, CheckLocationMutationVariables>;
export const CheckPhoneDocument = gql`
    mutation checkPhone($phone: String!) {
  checkPhone(phone: $phone) {
    id
    name
    email
    phone
    avatar
    superUser
  }
}
    `;
export type CheckPhoneMutationFn = ApolloReactCommon.MutationFunction<CheckPhoneMutation, CheckPhoneMutationVariables>;

    export function useCheckPhoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckPhoneMutation, CheckPhoneMutationVariables>) {
      return ApolloReactHooks.useMutation<CheckPhoneMutation, CheckPhoneMutationVariables>(CheckPhoneDocument, baseOptions);
    };
export type CheckPhoneMutationHookResult = ReturnType<typeof useCheckPhoneMutation>;
export type CheckPhoneMutationResult = ApolloReactCommon.MutationResult<CheckPhoneMutation>;
export type CheckPhoneMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckPhoneMutation, CheckPhoneMutationVariables>;
export const ClaimDeviceDocument = gql`
    mutation ClaimDevice($qrCode: String!, $locationId: ID!, $update: Boolean!) {
  claimDevice(qrCode: $qrCode, locationId: $locationId, update: $update) {
    ...deviceBasicInfo
  }
}
    ${DeviceBasicInfoFragmentDoc}`;
export type ClaimDeviceMutationFn = ApolloReactCommon.MutationFunction<ClaimDeviceMutation, ClaimDeviceMutationVariables>;

    export function useClaimDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimDeviceMutation, ClaimDeviceMutationVariables>) {
      return ApolloReactHooks.useMutation<ClaimDeviceMutation, ClaimDeviceMutationVariables>(ClaimDeviceDocument, baseOptions);
    };
export type ClaimDeviceMutationHookResult = ReturnType<typeof useClaimDeviceMutation>;
export type ClaimDeviceMutationResult = ApolloReactCommon.MutationResult<ClaimDeviceMutation>;
export type ClaimDeviceMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimDeviceMutation, ClaimDeviceMutationVariables>;
export const ConfirmRegistrationMailDocument = gql`
    mutation ConfirmRegistrationMail($email: String!, $token: String!) {
  confirmRegistrationMail(email: $email, token: $token)
}
    `;
export type ConfirmRegistrationMailMutationFn = ApolloReactCommon.MutationFunction<ConfirmRegistrationMailMutation, ConfirmRegistrationMailMutationVariables>;

    export function useConfirmRegistrationMailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmRegistrationMailMutation, ConfirmRegistrationMailMutationVariables>) {
      return ApolloReactHooks.useMutation<ConfirmRegistrationMailMutation, ConfirmRegistrationMailMutationVariables>(ConfirmRegistrationMailDocument, baseOptions);
    };
export type ConfirmRegistrationMailMutationHookResult = ReturnType<typeof useConfirmRegistrationMailMutation>;
export type ConfirmRegistrationMailMutationResult = ApolloReactCommon.MutationResult<ConfirmRegistrationMailMutation>;
export type ConfirmRegistrationMailMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmRegistrationMailMutation, ConfirmRegistrationMailMutationVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($locationName: String!, $user: String!) {
  createLocation(locationName: $locationName, user: $user) {
    id
    name
  }
}
    `;
export type CreateLocationMutationFn = ApolloReactCommon.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

    export function useCreateLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
      return ApolloReactHooks.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, baseOptions);
    };
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = ApolloReactCommon.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const DeleteDeviceTriggerDocument = gql`
    mutation DeleteDeviceTrigger($locationId: String!, $triggerId: String!) {
  deleteDeviceTrigger(locationId: $locationId, triggerId: $triggerId)
}
    `;
export type DeleteDeviceTriggerMutationFn = ApolloReactCommon.MutationFunction<DeleteDeviceTriggerMutation, DeleteDeviceTriggerMutationVariables>;

    export function useDeleteDeviceTriggerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDeviceTriggerMutation, DeleteDeviceTriggerMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteDeviceTriggerMutation, DeleteDeviceTriggerMutationVariables>(DeleteDeviceTriggerDocument, baseOptions);
    };
export type DeleteDeviceTriggerMutationHookResult = ReturnType<typeof useDeleteDeviceTriggerMutation>;
export type DeleteDeviceTriggerMutationResult = ApolloReactCommon.MutationResult<DeleteDeviceTriggerMutation>;
export type DeleteDeviceTriggerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDeviceTriggerMutation, DeleteDeviceTriggerMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($email: String!) {
  deleteUser(email: $email)
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

    export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
      return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
    };
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const EnableDeviceTriggerDocument = gql`
    mutation EnableDeviceTrigger($locationId: String!, $triggerId: String!, $enable: String!) {
  enableDeviceTrigger(locationId: $locationId, triggerId: $triggerId, enable: $enable)
}
    `;
export type EnableDeviceTriggerMutationFn = ApolloReactCommon.MutationFunction<EnableDeviceTriggerMutation, EnableDeviceTriggerMutationVariables>;

    export function useEnableDeviceTriggerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableDeviceTriggerMutation, EnableDeviceTriggerMutationVariables>) {
      return ApolloReactHooks.useMutation<EnableDeviceTriggerMutation, EnableDeviceTriggerMutationVariables>(EnableDeviceTriggerDocument, baseOptions);
    };
export type EnableDeviceTriggerMutationHookResult = ReturnType<typeof useEnableDeviceTriggerMutation>;
export type EnableDeviceTriggerMutationResult = ApolloReactCommon.MutationResult<EnableDeviceTriggerMutation>;
export type EnableDeviceTriggerMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableDeviceTriggerMutation, EnableDeviceTriggerMutationVariables>;
export const ExecuteDeviceTriggerDocument = gql`
    mutation ExecuteDeviceTrigger($locationId: String!, $triggerId: String!) {
  executeDeviceTrigger(locationId: $locationId, triggerId: $triggerId)
}
    `;
export type ExecuteDeviceTriggerMutationFn = ApolloReactCommon.MutationFunction<ExecuteDeviceTriggerMutation, ExecuteDeviceTriggerMutationVariables>;

    export function useExecuteDeviceTriggerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExecuteDeviceTriggerMutation, ExecuteDeviceTriggerMutationVariables>) {
      return ApolloReactHooks.useMutation<ExecuteDeviceTriggerMutation, ExecuteDeviceTriggerMutationVariables>(ExecuteDeviceTriggerDocument, baseOptions);
    };
export type ExecuteDeviceTriggerMutationHookResult = ReturnType<typeof useExecuteDeviceTriggerMutation>;
export type ExecuteDeviceTriggerMutationResult = ApolloReactCommon.MutationResult<ExecuteDeviceTriggerMutation>;
export type ExecuteDeviceTriggerMutationOptions = ApolloReactCommon.BaseMutationOptions<ExecuteDeviceTriggerMutation, ExecuteDeviceTriggerMutationVariables>;
export const GetDeviceAmountDocument = gql`
    query GetDeviceAmount($iccid: String) {
  getDeviceAmount(iccid: $iccid) {
    phone
    iccid
    msisdn
    imsi
    activeDate
    openDate
    amount
    cardStatus
  }
}
    `;

    export function useGetDeviceAmountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceAmountQuery, GetDeviceAmountQueryVariables>) {
      return ApolloReactHooks.useQuery<GetDeviceAmountQuery, GetDeviceAmountQueryVariables>(GetDeviceAmountDocument, baseOptions);
    };
export type GetDeviceAmountQueryHookResult = ReturnType<typeof useGetDeviceAmountQuery>;
export type GetDeviceAmountQueryResult = ApolloReactCommon.QueryResult<GetDeviceAmountQuery, GetDeviceAmountQueryVariables>;
export const GetDeviceListDocument = gql`
    query GetDeviceList($deviceIds: [ID!]!) {
  deviceList(deviceIds: $deviceIds) {
    id
    deviceId
    deviceStatus
    name
    devicePlatform {
      id
      software {
        id
        version
        fileName
        isLatest
        model {
          id
          name
        }
      }
      model {
        id
        name
      }
    }
  }
}
    `;

    export function useGetDeviceListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceListQuery, GetDeviceListQueryVariables>) {
      return ApolloReactHooks.useQuery<GetDeviceListQuery, GetDeviceListQueryVariables>(GetDeviceListDocument, baseOptions);
    };
export type GetDeviceListQueryHookResult = ReturnType<typeof useGetDeviceListQuery>;
export type GetDeviceListQueryResult = ApolloReactCommon.QueryResult<GetDeviceListQuery, GetDeviceListQueryVariables>;
export const GetDeviceDocument = gql`
    query GetDevice($id: ID!) {
  device(id: $id) {
    ...deviceBasicInfo
  }
}
    ${DeviceBasicInfoFragmentDoc}`;

    export function useGetDeviceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeviceQuery, GetDeviceQueryVariables>) {
      return ApolloReactHooks.useQuery<GetDeviceQuery, GetDeviceQueryVariables>(GetDeviceDocument, baseOptions);
    };
export type GetDeviceQueryHookResult = ReturnType<typeof useGetDeviceQuery>;
export type GetDeviceQueryResult = ApolloReactCommon.QueryResult<GetDeviceQuery, GetDeviceQueryVariables>;
export const GetDevicesByLocationIdDocument = gql`
    query GetDevicesByLocationId($locationId: String!) {
  getDevicesByLocationId(locationId: $locationId) {
    ...deviceBasicInfo
  }
}
    ${DeviceBasicInfoFragmentDoc}`;

    export function useGetDevicesByLocationIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesByLocationIdQuery, GetDevicesByLocationIdQueryVariables>) {
      return ApolloReactHooks.useQuery<GetDevicesByLocationIdQuery, GetDevicesByLocationIdQueryVariables>(GetDevicesByLocationIdDocument, baseOptions);
    };
export type GetDevicesByLocationIdQueryHookResult = ReturnType<typeof useGetDevicesByLocationIdQuery>;
export type GetDevicesByLocationIdQueryResult = ApolloReactCommon.QueryResult<GetDevicesByLocationIdQuery, GetDevicesByLocationIdQueryVariables>;
export const GetDevicesByLocationNameDocument = gql`
    query GetDevicesByLocationName($locationName: String!) {
  getDevicesByLocationName(locationName: $locationName) {
    ...deviceBasicInfo
  }
}
    ${DeviceBasicInfoFragmentDoc}`;

    export function useGetDevicesByLocationNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesByLocationNameQuery, GetDevicesByLocationNameQueryVariables>) {
      return ApolloReactHooks.useQuery<GetDevicesByLocationNameQuery, GetDevicesByLocationNameQueryVariables>(GetDevicesByLocationNameDocument, baseOptions);
    };
export type GetDevicesByLocationNameQueryHookResult = ReturnType<typeof useGetDevicesByLocationNameQuery>;
export type GetDevicesByLocationNameQueryResult = ApolloReactCommon.QueryResult<GetDevicesByLocationNameQuery, GetDevicesByLocationNameQueryVariables>;
export const GetDevicesDocument = gql`
    query getDevices($locationId: ID!, $sort: DeviceSortByOption, $sortOrder: SortOrderOption, $pageNo: Int, $pageSize: Int, $filter: DeviceFilterOption, $deviceId: String) {
  devices(locationId: $locationId, pageNo: $pageNo, pageSize: $pageSize, sortBy: $sort, sortOrder: $sortOrder, filter: $filter, deviceId: $deviceId) {
    devices {
      ...deviceBasicInfo
    }
    totalFilteredDevicesCount
  }
}
    ${DeviceBasicInfoFragmentDoc}`;

    export function useGetDevicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesQuery, GetDevicesQueryVariables>) {
      return ApolloReactHooks.useQuery<GetDevicesQuery, GetDevicesQueryVariables>(GetDevicesDocument, baseOptions);
    };
export type GetDevicesQueryHookResult = ReturnType<typeof useGetDevicesQuery>;
export type GetDevicesQueryResult = ApolloReactCommon.QueryResult<GetDevicesQuery, GetDevicesQueryVariables>;
export const GetLocationSummaryDocument = gql`
    query GetLocationSummary($locationId: ID!) {
  locationSummary(locationId: $locationId) {
    model {
      label
      value
    }
    power {
      label
      value
    }
    software {
      label
      value
    }
  }
}
    `;

    export function useGetLocationSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationSummaryQuery, GetLocationSummaryQueryVariables>) {
      return ApolloReactHooks.useQuery<GetLocationSummaryQuery, GetLocationSummaryQueryVariables>(GetLocationSummaryDocument, baseOptions);
    };
export type GetLocationSummaryQueryHookResult = ReturnType<typeof useGetLocationSummaryQuery>;
export type GetLocationSummaryQueryResult = ApolloReactCommon.QueryResult<GetLocationSummaryQuery, GetLocationSummaryQueryVariables>;
export const GetLocationDocument = gql`
    query GetLocation($id: ID!) {
  location(id: $id) {
    id
    name
  }
}
    `;

    export function useGetLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
      return ApolloReactHooks.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, baseOptions);
    };
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationQueryResult = ApolloReactCommon.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export const GetLocationsDocument = gql`
    query GetLocations($user: String!, $pageNo: Int, $pageSize: Int) {
  locations(user: $user, pageNo: $pageNo, pageSize: $pageSize) {
    id
    name
    deviceCount
  }
}
    `;

    export function useGetLocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, baseOptions);
    };
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsQueryResult = ApolloReactCommon.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetModelsDocument = gql`
    query GetModels {
  models {
    id
    name
  }
}
    `;

    export function useGetModelsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetModelsQuery, GetModelsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetModelsQuery, GetModelsQueryVariables>(GetModelsDocument, baseOptions);
    };
export type GetModelsQueryHookResult = ReturnType<typeof useGetModelsQuery>;
export type GetModelsQueryResult = ApolloReactCommon.QueryResult<GetModelsQuery, GetModelsQueryVariables>;
export const GetNbiotAcountDocument = gql`
    query GetNbiotAcount($account: String) {
  getNbiotAcount(account: $account) {
    accountId
    accountName
    amount
    lateFree
  }
}
    `;

    export function useGetNbiotAcountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNbiotAcountQuery, GetNbiotAcountQueryVariables>) {
      return ApolloReactHooks.useQuery<GetNbiotAcountQuery, GetNbiotAcountQueryVariables>(GetNbiotAcountDocument, baseOptions);
    };
export type GetNbiotAcountQueryHookResult = ReturnType<typeof useGetNbiotAcountQuery>;
export type GetNbiotAcountQueryResult = ApolloReactCommon.QueryResult<GetNbiotAcountQuery, GetNbiotAcountQueryVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($locationId: ID!, $pageNo: Int = 0, $pageSize: Int = 10) {
  getNotifications(locationId: $locationId, pageNo: $pageNo, pageSize: $pageSize) {
    notificationList {
      id
      title
      message
      locationId
      date
      notificationType
    }
  }
}
    `;

    export function useGetNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
    };
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsQueryResult = ApolloReactCommon.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetOfflineDevicesDocument = gql`
    query GetOfflineDevices {
  getOfflineDevices {
    id
    deviceStatus
    lastSeen
    name
  }
}
    `;

    export function useGetOfflineDevicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOfflineDevicesQuery, GetOfflineDevicesQueryVariables>) {
      return ApolloReactHooks.useQuery<GetOfflineDevicesQuery, GetOfflineDevicesQueryVariables>(GetOfflineDevicesDocument, baseOptions);
    };
export type GetOfflineDevicesQueryHookResult = ReturnType<typeof useGetOfflineDevicesQuery>;
export type GetOfflineDevicesQueryResult = ApolloReactCommon.QueryResult<GetOfflineDevicesQuery, GetOfflineDevicesQueryVariables>;
export const GetSmsCodeDocument = gql`
    mutation GetSmsCode($nationCode: String!, $phone: String!) {
  getSmsCode(nationCode: $nationCode, phone: $phone)
}
    `;
export type GetSmsCodeMutationFn = ApolloReactCommon.MutationFunction<GetSmsCodeMutation, GetSmsCodeMutationVariables>;

    export function useGetSmsCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetSmsCodeMutation, GetSmsCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<GetSmsCodeMutation, GetSmsCodeMutationVariables>(GetSmsCodeDocument, baseOptions);
    };
export type GetSmsCodeMutationHookResult = ReturnType<typeof useGetSmsCodeMutation>;
export type GetSmsCodeMutationResult = ApolloReactCommon.MutationResult<GetSmsCodeMutation>;
export type GetSmsCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<GetSmsCodeMutation, GetSmsCodeMutationVariables>;
export const GetSoftwaresForModelDocument = gql`
    query GetSoftwaresForModel($modelId: ID!) {
  getSoftwaresForModel(modelId: $modelId) {
    id
    version
    fileName
    isLatest
    model {
      id
      name
    }
  }
}
    `;

    export function useGetSoftwaresForModelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSoftwaresForModelQuery, GetSoftwaresForModelQueryVariables>) {
      return ApolloReactHooks.useQuery<GetSoftwaresForModelQuery, GetSoftwaresForModelQueryVariables>(GetSoftwaresForModelDocument, baseOptions);
    };
export type GetSoftwaresForModelQueryHookResult = ReturnType<typeof useGetSoftwaresForModelQuery>;
export type GetSoftwaresForModelQueryResult = ApolloReactCommon.QueryResult<GetSoftwaresForModelQuery, GetSoftwaresForModelQueryVariables>;
export const GetTriggerDocument = gql`
    query GetTrigger($id: ID!) {
  trigger(id: $id) {
    id
    name
    enable
    dpcondition
    dpaction
  }
}
    `;

    export function useGetTriggerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTriggerQuery, GetTriggerQueryVariables>) {
      return ApolloReactHooks.useQuery<GetTriggerQuery, GetTriggerQueryVariables>(GetTriggerDocument, baseOptions);
    };
export type GetTriggerQueryHookResult = ReturnType<typeof useGetTriggerQuery>;
export type GetTriggerQueryResult = ApolloReactCommon.QueryResult<GetTriggerQuery, GetTriggerQueryVariables>;
export const GetTriggersDocument = gql`
    query GetTriggers($locationId: ID!) {
  getTriggers(locationId: $locationId) {
    id
    name
    enable
    dpcondition
    dpaction
  }
}
    `;

    export function useGetTriggersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTriggersQuery, GetTriggersQueryVariables>) {
      return ApolloReactHooks.useQuery<GetTriggersQuery, GetTriggersQueryVariables>(GetTriggersDocument, baseOptions);
    };
export type GetTriggersQueryHookResult = ReturnType<typeof useGetTriggersQuery>;
export type GetTriggersQueryResult = ApolloReactCommon.QueryResult<GetTriggersQuery, GetTriggersQueryVariables>;
export const GetUserByEmailDocument = gql`
    query GetUserByEmail($email: String!) {
  getUserByEmail(email: $email) {
    id
    name
    email
    phone
    avatar
    superUser
  }
}
    `;

    export function useGetUserByEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
      return ApolloReactHooks.useQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, baseOptions);
    };
export type GetUserByEmailQueryHookResult = ReturnType<typeof useGetUserByEmailQuery>;
export type GetUserByEmailQueryResult = ApolloReactCommon.QueryResult<GetUserByEmailQuery, GetUserByEmailQueryVariables>;
export const GetUserByPhoneDocument = gql`
    query GetUserByPhone($phone: String!) {
  getUserByPhone(phone: $phone) {
    id
    name
    email
    phone
    avatar
    superUser
  }
}
    `;

    export function useGetUserByPhoneQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserByPhoneQuery, GetUserByPhoneQueryVariables>) {
      return ApolloReactHooks.useQuery<GetUserByPhoneQuery, GetUserByPhoneQueryVariables>(GetUserByPhoneDocument, baseOptions);
    };
export type GetUserByPhoneQueryHookResult = ReturnType<typeof useGetUserByPhoneQuery>;
export type GetUserByPhoneQueryResult = ApolloReactCommon.QueryResult<GetUserByPhoneQuery, GetUserByPhoneQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  me {
    id
    name
    email
    phone
    avatar
    superUser
  }
}
    `;

    export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
      return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
    };
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersByLocationDocument = gql`
    query getUsersByLocation($locationId: ID!) {
  getUsersWithinLocation(locationId: $locationId) {
    ...userBasicInfo
  }
}
    ${UserBasicInfoFragmentDoc}`;

    export function useGetUsersByLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByLocationQuery, GetUsersByLocationQueryVariables>) {
      return ApolloReactHooks.useQuery<GetUsersByLocationQuery, GetUsersByLocationQueryVariables>(GetUsersByLocationDocument, baseOptions);
    };
export type GetUsersByLocationQueryHookResult = ReturnType<typeof useGetUsersByLocationQuery>;
export type GetUsersByLocationQueryResult = ApolloReactCommon.QueryResult<GetUsersByLocationQuery, GetUsersByLocationQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    id
    name
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

    export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
      return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
    };
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

    export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
      return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
    };
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ReChargeDocument = gql`
    mutation ReCharge($account: String, $chargeMoney: Int) {
  reCharge(account: $account, chargeMoney: $chargeMoney) {
    iccid
    url
    orderNo
  }
}
    `;
export type ReChargeMutationFn = ApolloReactCommon.MutationFunction<ReChargeMutation, ReChargeMutationVariables>;

    export function useReChargeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReChargeMutation, ReChargeMutationVariables>) {
      return ApolloReactHooks.useMutation<ReChargeMutation, ReChargeMutationVariables>(ReChargeDocument, baseOptions);
    };
export type ReChargeMutationHookResult = ReturnType<typeof useReChargeMutation>;
export type ReChargeMutationResult = ApolloReactCommon.MutationResult<ReChargeMutation>;
export type ReChargeMutationOptions = ApolloReactCommon.BaseMutationOptions<ReChargeMutation, ReChargeMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($user: UserInputUpdate!) {
  registerUser(user: $user)
}
    `;
export type RegisterUserMutationFn = ApolloReactCommon.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

    export function useRegisterUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
      return ApolloReactHooks.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, baseOptions);
    };
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = ApolloReactCommon.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const RemoveDevicesFromLocationDocument = gql`
    mutation RemoveDevicesFromLocation($deviceIds: [ID!]!) {
  removeDevicesFromLocation(deviceIds: $deviceIds)
}
    `;
export type RemoveDevicesFromLocationMutationFn = ApolloReactCommon.MutationFunction<RemoveDevicesFromLocationMutation, RemoveDevicesFromLocationMutationVariables>;

    export function useRemoveDevicesFromLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveDevicesFromLocationMutation, RemoveDevicesFromLocationMutationVariables>) {
      return ApolloReactHooks.useMutation<RemoveDevicesFromLocationMutation, RemoveDevicesFromLocationMutationVariables>(RemoveDevicesFromLocationDocument, baseOptions);
    };
export type RemoveDevicesFromLocationMutationHookResult = ReturnType<typeof useRemoveDevicesFromLocationMutation>;
export type RemoveDevicesFromLocationMutationResult = ApolloReactCommon.MutationResult<RemoveDevicesFromLocationMutation>;
export type RemoveDevicesFromLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveDevicesFromLocationMutation, RemoveDevicesFromLocationMutationVariables>;
export const RemoveLocationDocument = gql`
    mutation RemoveLocation($locationId: ID!) {
  removeLocation(locationId: $locationId)
}
    `;
export type RemoveLocationMutationFn = ApolloReactCommon.MutationFunction<RemoveLocationMutation, RemoveLocationMutationVariables>;

    export function useRemoveLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveLocationMutation, RemoveLocationMutationVariables>) {
      return ApolloReactHooks.useMutation<RemoveLocationMutation, RemoveLocationMutationVariables>(RemoveLocationDocument, baseOptions);
    };
export type RemoveLocationMutationHookResult = ReturnType<typeof useRemoveLocationMutation>;
export type RemoveLocationMutationResult = ApolloReactCommon.MutationResult<RemoveLocationMutation>;
export type RemoveLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveLocationMutation, RemoveLocationMutationVariables>;
export const RemoveMessagesDocument = gql`
    mutation RemoveMessages($locationId: ID!) {
  removeMessages(locationId: $locationId)
}
    `;
export type RemoveMessagesMutationFn = ApolloReactCommon.MutationFunction<RemoveMessagesMutation, RemoveMessagesMutationVariables>;

    export function useRemoveMessagesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMessagesMutation, RemoveMessagesMutationVariables>) {
      return ApolloReactHooks.useMutation<RemoveMessagesMutation, RemoveMessagesMutationVariables>(RemoveMessagesDocument, baseOptions);
    };
export type RemoveMessagesMutationHookResult = ReturnType<typeof useRemoveMessagesMutation>;
export type RemoveMessagesMutationResult = ApolloReactCommon.MutationResult<RemoveMessagesMutation>;
export type RemoveMessagesMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMessagesMutation, RemoveMessagesMutationVariables>;
export const RemoveSoftwareDocument = gql`
    mutation RemoveSoftware($softwareid: ID!) {
  removeSoftware(softwareid: $softwareid)
}
    `;
export type RemoveSoftwareMutationFn = ApolloReactCommon.MutationFunction<RemoveSoftwareMutation, RemoveSoftwareMutationVariables>;

    export function useRemoveSoftwareMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSoftwareMutation, RemoveSoftwareMutationVariables>) {
      return ApolloReactHooks.useMutation<RemoveSoftwareMutation, RemoveSoftwareMutationVariables>(RemoveSoftwareDocument, baseOptions);
    };
export type RemoveSoftwareMutationHookResult = ReturnType<typeof useRemoveSoftwareMutation>;
export type RemoveSoftwareMutationResult = ApolloReactCommon.MutationResult<RemoveSoftwareMutation>;
export type RemoveSoftwareMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSoftwareMutation, RemoveSoftwareMutationVariables>;
export const SendDevicesPowerOnDocument = gql`
    mutation SendDevicesPowerOn($deviceIds: [ID!]!) {
  sendDevicesPowerOn(deviceIds: $deviceIds) {
    id
    powerStatus
  }
}
    `;
export type SendDevicesPowerOnMutationFn = ApolloReactCommon.MutationFunction<SendDevicesPowerOnMutation, SendDevicesPowerOnMutationVariables>;

    export function useSendDevicesPowerOnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendDevicesPowerOnMutation, SendDevicesPowerOnMutationVariables>) {
      return ApolloReactHooks.useMutation<SendDevicesPowerOnMutation, SendDevicesPowerOnMutationVariables>(SendDevicesPowerOnDocument, baseOptions);
    };
export type SendDevicesPowerOnMutationHookResult = ReturnType<typeof useSendDevicesPowerOnMutation>;
export type SendDevicesPowerOnMutationResult = ApolloReactCommon.MutationResult<SendDevicesPowerOnMutation>;
export type SendDevicesPowerOnMutationOptions = ApolloReactCommon.BaseMutationOptions<SendDevicesPowerOnMutation, SendDevicesPowerOnMutationVariables>;
export const SendDevicesSoftwareUpdateDocument = gql`
    mutation SendDevicesSoftwareUpdate($deviceIds: [ID!]!, $softwareId: ID!, $roomId: String!) {
  sendDevicesSoftwareUpdate(deviceIds: $deviceIds, softwareId: $softwareId, roomId: $roomId) {
    ...deviceBasicInfo
  }
}
    ${DeviceBasicInfoFragmentDoc}`;
export type SendDevicesSoftwareUpdateMutationFn = ApolloReactCommon.MutationFunction<SendDevicesSoftwareUpdateMutation, SendDevicesSoftwareUpdateMutationVariables>;

    export function useSendDevicesSoftwareUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendDevicesSoftwareUpdateMutation, SendDevicesSoftwareUpdateMutationVariables>) {
      return ApolloReactHooks.useMutation<SendDevicesSoftwareUpdateMutation, SendDevicesSoftwareUpdateMutationVariables>(SendDevicesSoftwareUpdateDocument, baseOptions);
    };
export type SendDevicesSoftwareUpdateMutationHookResult = ReturnType<typeof useSendDevicesSoftwareUpdateMutation>;
export type SendDevicesSoftwareUpdateMutationResult = ApolloReactCommon.MutationResult<SendDevicesSoftwareUpdateMutation>;
export type SendDevicesSoftwareUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SendDevicesSoftwareUpdateMutation, SendDevicesSoftwareUpdateMutationVariables>;
export const SendDevicesToRebootDocument = gql`
    mutation SendDevicesToReboot($deviceIds: [ID!]!) {
  sendDevicesToReboot(deviceIds: $deviceIds) {
    id
    powerStatus
  }
}
    `;
export type SendDevicesToRebootMutationFn = ApolloReactCommon.MutationFunction<SendDevicesToRebootMutation, SendDevicesToRebootMutationVariables>;

    export function useSendDevicesToRebootMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendDevicesToRebootMutation, SendDevicesToRebootMutationVariables>) {
      return ApolloReactHooks.useMutation<SendDevicesToRebootMutation, SendDevicesToRebootMutationVariables>(SendDevicesToRebootDocument, baseOptions);
    };
export type SendDevicesToRebootMutationHookResult = ReturnType<typeof useSendDevicesToRebootMutation>;
export type SendDevicesToRebootMutationResult = ApolloReactCommon.MutationResult<SendDevicesToRebootMutation>;
export type SendDevicesToRebootMutationOptions = ApolloReactCommon.BaseMutationOptions<SendDevicesToRebootMutation, SendDevicesToRebootMutationVariables>;
export const SendDevicesToStandbyDocument = gql`
    mutation SendDevicesToStandby($deviceIds: [ID!]!) {
  sendDevicesToStandby(deviceIds: $deviceIds) {
    id
    powerStatus
  }
}
    `;
export type SendDevicesToStandbyMutationFn = ApolloReactCommon.MutationFunction<SendDevicesToStandbyMutation, SendDevicesToStandbyMutationVariables>;

    export function useSendDevicesToStandbyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendDevicesToStandbyMutation, SendDevicesToStandbyMutationVariables>) {
      return ApolloReactHooks.useMutation<SendDevicesToStandbyMutation, SendDevicesToStandbyMutationVariables>(SendDevicesToStandbyDocument, baseOptions);
    };
export type SendDevicesToStandbyMutationHookResult = ReturnType<typeof useSendDevicesToStandbyMutation>;
export type SendDevicesToStandbyMutationResult = ApolloReactCommon.MutationResult<SendDevicesToStandbyMutation>;
export type SendDevicesToStandbyMutationOptions = ApolloReactCommon.BaseMutationOptions<SendDevicesToStandbyMutation, SendDevicesToStandbyMutationVariables>;
export const SendSwitchPowerDocument = gql`
    mutation SendSwitchPower($deviceId: ID!, $index: ID!, $bPower: Boolean!) {
  sendSwitchPower(deviceId: $deviceId, index: $index, bPower: $bPower)
}
    `;
export type SendSwitchPowerMutationFn = ApolloReactCommon.MutationFunction<SendSwitchPowerMutation, SendSwitchPowerMutationVariables>;

    export function useSendSwitchPowerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendSwitchPowerMutation, SendSwitchPowerMutationVariables>) {
      return ApolloReactHooks.useMutation<SendSwitchPowerMutation, SendSwitchPowerMutationVariables>(SendSwitchPowerDocument, baseOptions);
    };
export type SendSwitchPowerMutationHookResult = ReturnType<typeof useSendSwitchPowerMutation>;
export type SendSwitchPowerMutationResult = ApolloReactCommon.MutationResult<SendSwitchPowerMutation>;
export type SendSwitchPowerMutationOptions = ApolloReactCommon.BaseMutationOptions<SendSwitchPowerMutation, SendSwitchPowerMutationVariables>;
export const SendChangePasswordDocument = gql`
    mutation SendChangePassword($passwordRecovery: PasswordRecovery!) {
  sendChangePassword(passwordRecovery: $passwordRecovery)
}
    `;
export type SendChangePasswordMutationFn = ApolloReactCommon.MutationFunction<SendChangePasswordMutation, SendChangePasswordMutationVariables>;

    export function useSendChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendChangePasswordMutation, SendChangePasswordMutationVariables>) {
      return ApolloReactHooks.useMutation<SendChangePasswordMutation, SendChangePasswordMutationVariables>(SendChangePasswordDocument, baseOptions);
    };
export type SendChangePasswordMutationHookResult = ReturnType<typeof useSendChangePasswordMutation>;
export type SendChangePasswordMutationResult = ApolloReactCommon.MutationResult<SendChangePasswordMutation>;
export type SendChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<SendChangePasswordMutation, SendChangePasswordMutationVariables>;
export const SendPasswordRecoveryEmailDocument = gql`
    mutation SendPasswordRecoveryEmail($email: String!, $language: String!) {
  sendPasswordRecoveryEmail(email: $email, language: $language)
}
    `;
export type SendPasswordRecoveryEmailMutationFn = ApolloReactCommon.MutationFunction<SendPasswordRecoveryEmailMutation, SendPasswordRecoveryEmailMutationVariables>;

    export function useSendPasswordRecoveryEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendPasswordRecoveryEmailMutation, SendPasswordRecoveryEmailMutationVariables>) {
      return ApolloReactHooks.useMutation<SendPasswordRecoveryEmailMutation, SendPasswordRecoveryEmailMutationVariables>(SendPasswordRecoveryEmailDocument, baseOptions);
    };
export type SendPasswordRecoveryEmailMutationHookResult = ReturnType<typeof useSendPasswordRecoveryEmailMutation>;
export type SendPasswordRecoveryEmailMutationResult = ApolloReactCommon.MutationResult<SendPasswordRecoveryEmailMutation>;
export type SendPasswordRecoveryEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendPasswordRecoveryEmailMutation, SendPasswordRecoveryEmailMutationVariables>;
export const SendRegistrationMailDocument = gql`
    mutation SendRegistrationMail($email: String!) {
  sendRegistrationMail(email: $email)
}
    `;
export type SendRegistrationMailMutationFn = ApolloReactCommon.MutationFunction<SendRegistrationMailMutation, SendRegistrationMailMutationVariables>;

    export function useSendRegistrationMailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendRegistrationMailMutation, SendRegistrationMailMutationVariables>) {
      return ApolloReactHooks.useMutation<SendRegistrationMailMutation, SendRegistrationMailMutationVariables>(SendRegistrationMailDocument, baseOptions);
    };
export type SendRegistrationMailMutationHookResult = ReturnType<typeof useSendRegistrationMailMutation>;
export type SendRegistrationMailMutationResult = ApolloReactCommon.MutationResult<SendRegistrationMailMutation>;
export type SendRegistrationMailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendRegistrationMailMutation, SendRegistrationMailMutationVariables>;
export const UpdateDeviceAmountDocument = gql`
    mutation updateDeviceAmount($iccid: String, $amount: String) {
  updateDeviceAmount(iccid: $iccid, amount: $amount)
}
    `;
export type UpdateDeviceAmountMutationFn = ApolloReactCommon.MutationFunction<UpdateDeviceAmountMutation, UpdateDeviceAmountMutationVariables>;

    export function useUpdateDeviceAmountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDeviceAmountMutation, UpdateDeviceAmountMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateDeviceAmountMutation, UpdateDeviceAmountMutationVariables>(UpdateDeviceAmountDocument, baseOptions);
    };
export type UpdateDeviceAmountMutationHookResult = ReturnType<typeof useUpdateDeviceAmountMutation>;
export type UpdateDeviceAmountMutationResult = ApolloReactCommon.MutationResult<UpdateDeviceAmountMutation>;
export type UpdateDeviceAmountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDeviceAmountMutation, UpdateDeviceAmountMutationVariables>;
export const UpdateDeviceDpNameDocument = gql`
    mutation UpdateDeviceDpName($location: String!, $deviceId: ID!, $parentDpid: String!, $dpid: String!, $name: String!) {
  updateDeviceDpName(location: $location, deviceId: $deviceId, parentDpid: $parentDpid, dpid: $dpid, name: $name)
}
    `;
export type UpdateDeviceDpNameMutationFn = ApolloReactCommon.MutationFunction<UpdateDeviceDpNameMutation, UpdateDeviceDpNameMutationVariables>;

    export function useUpdateDeviceDpNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDeviceDpNameMutation, UpdateDeviceDpNameMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateDeviceDpNameMutation, UpdateDeviceDpNameMutationVariables>(UpdateDeviceDpNameDocument, baseOptions);
    };
export type UpdateDeviceDpNameMutationHookResult = ReturnType<typeof useUpdateDeviceDpNameMutation>;
export type UpdateDeviceDpNameMutationResult = ApolloReactCommon.MutationResult<UpdateDeviceDpNameMutation>;
export type UpdateDeviceDpNameMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDeviceDpNameMutation, UpdateDeviceDpNameMutationVariables>;
export const UpdateDeviceDpDocument = gql`
    mutation UpdateDeviceDp($location: String!, $deviceId: ID!, $parentDpid: String!, $dpid: String!, $dataType: String!, $value: String!) {
  updateDeviceDp(location: $location, deviceId: $deviceId, parentDpid: $parentDpid, dpid: $dpid, dataType: $dataType, value: $value)
}
    `;
export type UpdateDeviceDpMutationFn = ApolloReactCommon.MutationFunction<UpdateDeviceDpMutation, UpdateDeviceDpMutationVariables>;

    export function useUpdateDeviceDpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDeviceDpMutation, UpdateDeviceDpMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateDeviceDpMutation, UpdateDeviceDpMutationVariables>(UpdateDeviceDpDocument, baseOptions);
    };
export type UpdateDeviceDpMutationHookResult = ReturnType<typeof useUpdateDeviceDpMutation>;
export type UpdateDeviceDpMutationResult = ApolloReactCommon.MutationResult<UpdateDeviceDpMutation>;
export type UpdateDeviceDpMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDeviceDpMutation, UpdateDeviceDpMutationVariables>;
export const UpdateDeviceNameDocument = gql`
    mutation UpdateDeviceName($input: UpdateDeviceNameInput!) {
  updateDeviceName(input: $input) {
    ...deviceBasicInfo
  }
}
    ${DeviceBasicInfoFragmentDoc}`;
export type UpdateDeviceNameMutationFn = ApolloReactCommon.MutationFunction<UpdateDeviceNameMutation, UpdateDeviceNameMutationVariables>;

    export function useUpdateDeviceNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDeviceNameMutation, UpdateDeviceNameMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateDeviceNameMutation, UpdateDeviceNameMutationVariables>(UpdateDeviceNameDocument, baseOptions);
    };
export type UpdateDeviceNameMutationHookResult = ReturnType<typeof useUpdateDeviceNameMutation>;
export type UpdateDeviceNameMutationResult = ApolloReactCommon.MutationResult<UpdateDeviceNameMutation>;
export type UpdateDeviceNameMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDeviceNameMutation, UpdateDeviceNameMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($locationName: String!, $locationId: ID!) {
  updateLocation(locationName: $locationName, locationId: $locationId) {
    id
    name
  }
}
    `;
export type UpdateLocationMutationFn = ApolloReactCommon.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

    export function useUpdateLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, baseOptions);
    };
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = ApolloReactCommon.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const UpdateTriggerActionDocument = gql`
    mutation UpdateTriggerAction($input: UpdateTriggerActionInput!) {
  updateTriggerAction(input: $input) {
    id
    name
    enable
    dpcondition
    dpaction
  }
}
    `;
export type UpdateTriggerActionMutationFn = ApolloReactCommon.MutationFunction<UpdateTriggerActionMutation, UpdateTriggerActionMutationVariables>;

    export function useUpdateTriggerActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTriggerActionMutation, UpdateTriggerActionMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateTriggerActionMutation, UpdateTriggerActionMutationVariables>(UpdateTriggerActionDocument, baseOptions);
    };
export type UpdateTriggerActionMutationHookResult = ReturnType<typeof useUpdateTriggerActionMutation>;
export type UpdateTriggerActionMutationResult = ApolloReactCommon.MutationResult<UpdateTriggerActionMutation>;
export type UpdateTriggerActionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTriggerActionMutation, UpdateTriggerActionMutationVariables>;
export const UpdateTriggerConditionDocument = gql`
    mutation UpdateTriggerCondition($input: UpdateTriggerConditionInput!) {
  updateTriggerCondition(input: $input) {
    id
    name
    enable
    dpcondition
    dpaction
  }
}
    `;
export type UpdateTriggerConditionMutationFn = ApolloReactCommon.MutationFunction<UpdateTriggerConditionMutation, UpdateTriggerConditionMutationVariables>;

    export function useUpdateTriggerConditionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTriggerConditionMutation, UpdateTriggerConditionMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateTriggerConditionMutation, UpdateTriggerConditionMutationVariables>(UpdateTriggerConditionDocument, baseOptions);
    };
export type UpdateTriggerConditionMutationHookResult = ReturnType<typeof useUpdateTriggerConditionMutation>;
export type UpdateTriggerConditionMutationResult = ApolloReactCommon.MutationResult<UpdateTriggerConditionMutation>;
export type UpdateTriggerConditionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTriggerConditionMutation, UpdateTriggerConditionMutationVariables>;
export const UpdateTriggerNameDocument = gql`
    mutation UpdateTriggerName($input: UpdateTriggerNameInput!) {
  updateTriggerName(input: $input) {
    id
    name
    enable
    dpcondition
    dpaction
  }
}
    `;
export type UpdateTriggerNameMutationFn = ApolloReactCommon.MutationFunction<UpdateTriggerNameMutation, UpdateTriggerNameMutationVariables>;

    export function useUpdateTriggerNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTriggerNameMutation, UpdateTriggerNameMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateTriggerNameMutation, UpdateTriggerNameMutationVariables>(UpdateTriggerNameDocument, baseOptions);
    };
export type UpdateTriggerNameMutationHookResult = ReturnType<typeof useUpdateTriggerNameMutation>;
export type UpdateTriggerNameMutationResult = ApolloReactCommon.MutationResult<UpdateTriggerNameMutation>;
export type UpdateTriggerNameMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTriggerNameMutation, UpdateTriggerNameMutationVariables>;
export const VerifySmsCodeDocument = gql`
    mutation VerifySmsCode($phone: String!, $code: String!) {
  verifySmsCode(phone: $phone, code: $code)
}
    `;
export type VerifySmsCodeMutationFn = ApolloReactCommon.MutationFunction<VerifySmsCodeMutation, VerifySmsCodeMutationVariables>;

    export function useVerifySmsCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifySmsCodeMutation, VerifySmsCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<VerifySmsCodeMutation, VerifySmsCodeMutationVariables>(VerifySmsCodeDocument, baseOptions);
    };
export type VerifySmsCodeMutationHookResult = ReturnType<typeof useVerifySmsCodeMutation>;
export type VerifySmsCodeMutationResult = ApolloReactCommon.MutationResult<VerifySmsCodeMutation>;
export type VerifySmsCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifySmsCodeMutation, VerifySmsCodeMutationVariables>;