/* eslint-disable prettier/prettier */

import { t as translationTag } from 'ttag';
import urls from './urls';
import { ComponentLoader } from './async-route';

/**
 * MenuRoute describes information about the submenu for that route
 */
export type MenuRoute = {
    component: ComponentLoader;
    title: (t: typeof translationTag) => string;
    order?: number;
}

/**
 * Authenticated route describes information about the possible ui areas for an authenticated route
 */
export type AuthenticatedRoute = {
    main?: ComponentLoader;
    menu?: MenuRoute;
};

export type AuthenticatedRoutesByUrl = {
    [url: string]: AuthenticatedRoute;
};

/**
 * The routes which are publicly accessible
 */
export const publicRoutes = {
    [urls.login]: () => import(/* webpackChunkName: 'login', webpackMode: 'eager' */ '@/containers/login'),
    [urls.help]: () => import(/* webpackChunkName: 'registration', webpackPrefetch: true */ '@/containers/help'),
    [urls.registration]: () => import(/* webpackChunkName: 'registration', webpackPrefetch: true */ '@/containers/registration'),
    [urls.changepassword]: () => import(/* webpackChunkName: 'registration', webpackPrefetch: true */ '@/containers/changepassword'),
    [urls.qrcode]: () => import(/* webpackChunkName: 'registration', webpackPrefetch: true */ '@/containers/qrcode'),
};

function getTitle(){
    if(navigator.language ==="zh-CN"){
        return "设备管理";
    }
        return "DEVICE MANAGER"
    
}

function getTitle1(){
    if(navigator.language ==="zh-CN"){
        return "用户管理";
    }
        return "USER MANAGER"
    
}
function getTitle2(){
    if(navigator.language ==="zh-CN"){
        return "智能管理";
    }
        return "TRIGGER MANAGER"
    
}
/**
 * Routes which can only be visited after the user has authenticated
 */
export const authenticatedRoutes: AuthenticatedRoutesByUrl = {
    [urls.dashboard.allLocations]: {
        main: () => import(/* webpackChunkName: 'all-locations', webpackPrefetch: true */ '@/containers/all-locations'),
        menu: {
            component: () => import(/* webpackChunkName: 'all-locations', webpackPrefetch: true */ '@/containers/all-locations-menu'),
            title: getTitle(),
            order: 1,
        }
    },
    [urls.dashboard.singleLocation.pattern]: {
        main: () => import(/* webpackChunkName: 'single-location', webpackPrefetch: true */ '@/containers/single-location'),
    },
    [urls.users.pattern]: {
        main: () => import(/* webpackChunkName: 'user-management', webpackPrefetch: true */ '@/containers/user-management'),
        menu: {
            component: () => import(/* webpackChunkName: 'all-locations', webpackPrefetch: true */ '@/containers/user-management-menu'),
            title: getTitle1(),
            order: 1,
        }
    },
    [urls.triggers.pattern]: {
        main: () => import(/* webpackChunkName: 'user-management', webpackPrefetch: true */ '@/containers/trigger-management'),
        menu: {
            component: () => import(/* webpackChunkName: 'all-locations', webpackPrefetch: true */ '@/containers/trigger-management-menu'),
            title: getTitle2(),
            order: 1,
        }
    },

    
};
