import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import {
    InMemoryCache,
    IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { split, ApolloLink } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { getWebSocketEndpoint, getHttpEndPoint } from './api-endpoints';
import logout from './logout';
import fragmentMatchers from './generated-fragments';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: fragmentMatchers,
});

let client: ApolloClient<any>;

function addSubscriptionLink(link: ApolloLink) {
    const wsLink = new WebSocketLink({
        uri: getWebSocketEndpoint(),
        options: {
            reconnect: true,
        },
    });

    return split(
        ({ query }) => {
            const def = getMainDefinition(query);
            if (def.kind === 'OperationDefinition') {
                return def.operation === 'subscription';
            }
            return false;
        },
        wsLink,
        link
    );
}

function createHttpLink() {
    const errorLink = onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
            const isUnAuthenticated = graphQLErrors.some(
                err => err && err.extensions && err.extensions.code === '401'
            );

            if (isUnAuthenticated) {
                logout(client);
            }
        }
    });
    const httpLink = new HttpLink({
        uri: getHttpEndPoint(),
        credentials: 'include',
    });

    return errorLink.concat(httpLink);
}

function createLink() {
    const link = createHttpLink();
    /* shuisheng.hong
    if (process.env.ENABLE_SUBSCRIPTIONS === 'true') {
        link = addSubscriptionLink(link);
    }
*/
    return link;
}

function createCache() {
    return new InMemoryCache({
        fragmentMatcher,
        /**
         * In some cases apollo can't determine if something is already cached.
         * With cacheRedirects we can redirect a request to the cache.
         */
        cacheRedirects: {
            Query: {
                device: (_, args, { getCacheKey }) => {
                    return getCacheKey({ __typename: 'Device', id: args.id });
                },
                project: (_, args, { getCacheKey }) => {
                    return getCacheKey({ __typename: 'Project', id: args.id });
                },
                location: (_, args, { getCacheKey }) => {
                    return getCacheKey({ __typename: 'Location', id: args.id });
                },
            },
        },
    });
}

export default function setupApolloClient() {
    client = new ApolloClient({
        cache: createCache(),
        link: createLink(),
        queryDeduplication: true,
        connectToDevTools: process.env.NODE_ENV !== 'production',
        defaultOptions: {
            query: {
                errorPolicy: 'all',
            },
            watchQuery: {
                errorPolicy: 'all',
            },
        },
    });

    return client;
}
