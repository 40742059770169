import React from 'react';
import classNames from 'classnames';
import Icon from '@/components/icon';
import IconButton from '@/components/icon-button';
import iconCheckmark from '@/assets/images/icon_inline_checkmark-circle_fill.svg';
import iconWarning from '@/assets/images/icon_emergency-triangle.svg';
import iconClose from '@/assets/images/icon_boxed_cross_2.5pt.svg';
import iconInfo from '@/assets/images/icon_info.svg';
import useLocaleContext from '@/hooks/use-locale-context';
import { ToastMessageWithId } from '@/components/toast-provider/toast-context';
import styles from './toast-message-list.css';
import useTimeout from '@/components/toast-provider/use-timeout';
import { useTranslation } from 'react-i18next';

export type Props = ToastMessageWithId & {
    onCtaClick?: VoidFunction;
    onCloseClick: (id: string) => void;
    onAutoClose: (id: string) => void;
    className?: string;
};

const icons = {
    success: iconCheckmark,
    neutral: iconInfo,
    failure: iconWarning,
};

const ToastMessage = ({
    id,
    type,
    message,
    onCtaClick,
    onCloseClick,
    onAutoClose,
    className,
}: Props) => {
    //const { t } = useLocaleContext();
    const { t, i18n } = useTranslation();

    const closeDelay = !onCtaClick ? 3 : 10;
    useTimeout(closeDelay * 1000, () => {
        onAutoClose(id);
    });

    return (
        <div
            className={classNames(styles.item, styles[type], className)}
            role="alert"
        >
            <Icon size="L" className={styles.iconType} icon={icons[type]} />
            {message}

            {onCtaClick && type === 'neutral' && (
                <button
                    type="button"
                    onClick={onCtaClick}
                    className={styles.undo}
                >
                    {t`Undo`}
                </button>
            )}

            <IconButton
                iconSize="L"
                onClick={() => {
                    onCloseClick(id);
                }}
                title={t`Hide message`}
                className={styles.iconClose}
                icon={iconClose}
            />
        </div>
    );
};

export default React.memo(ToastMessage);
