import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styles from './toast-message-list.css';
import ToastMessageItem from './toast-message-item';
import useListAnimation from '@/hooks/use-list-animation';
import { ToastMessageWithId } from '@/components/toast-provider/toast-context';
import useToastContext from '@/hooks/use-toast-context';

type Props = {
    items: ToastMessageWithId[];
};

const ToastMessageList = ({ items }: Props) => {
    const { dismissToast } = useToastContext();

    const ref = useRef<HTMLDivElement>(null);
    useListAnimation(ref, {
        duration: 300,
        align: 'end',
        orientation: 'vertical',
    });

    const list = (
        <div ref={ref} className={styles.root}>
            <TransitionGroup component={null}>
                {items.map(item => {
                    return (
                        <CSSTransition
                            key={item.id}
                            timeout={{ enter: 500, exit: 200 }}
                            classNames={{
                                enter: styles.itemEnter,
                                enterActive: styles.itemEnterActive,
                                exit: styles.itemLeave,
                                exitActive: styles.itemLeaveActive,
                            }}
                        >
                            <ToastMessageItem
                                onCloseClick={dismissToast}
                                onAutoClose={dismissToast}
                                className={styles.message}
                                {...item}
                            />
                        </CSSTransition>
                    );
                })}
            </TransitionGroup>
        </div>
    );

    return ReactDOM.createPortal(list, document.body);
};

export default React.memo(ToastMessageList);
