import { nested, dynamicUrl } from './helpers';

const urls = {
    login: '/auth',

    registration: '/registration',
    changepassword: '/changepassword',
    help: '/help',
    qrcode: '/qrcode',

    dashboard: nested('/dashboard', {
        allProjects: 'projects',
        allLocations: 'locations',
        singleLocation: dynamicUrl<{ locationId: string }>(
            'locations/:locationId'
        ),
    }),

    create: nested('/create', {
        library: 'library',
        design: 'design',
    }),

    support: nested('/support', {
        faq: 'faq',
        about: 'about',
        termsAndConditions: 'terms-and-conditions',
        privacy: 'privacy',
    }),

    search: '/search',

    account: nested('/account', {
        personalSettings: 'personal-settings',
    }),

    users: dynamicUrl<{ locationId?: string }>('/users/:locationId?'),
    triggers: dynamicUrl<{ locationId?: string }>('/triggers/:locationId?'),

    notFound: '/404',
};

export const home = urls.dashboard.allLocations;
export const { registration } = urls;
export const { changepassword } = urls;
export const { qrcode } = urls;

export default urls;
