import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useUID } from 'react-uid';
import IconButton from '@/components/icon-button';
import useLocaleContext from '@/hooks/use-locale-context';
import iconEyeShow from '@/assets/images/icon_eye-show.svg';
import iconEyeHide from '@/assets/images/icon_eye-hide.svg';
import iconSearch from '@/assets/images/icon_boxed_search_2.5pt.svg';
import Icon from '@/components/icon';
import styles from './form-field.css';
import useFormValidation, {
    ValidationMessages,
    FormValidationEvents,
} from '@/hooks/use-form-validation';
import { useTranslation } from 'react-i18next';
/**
 * Every custom "form-field" should extend the following props
 */
export type BaseFormFieldProps = {
    alt?: boolean;
    sublabel?: React.ReactNode;
    disabled?: boolean;
    className?: string;
    hideLabel?: boolean;
    minimal?: boolean;
    label: string;
    validationMessages?: ValidationMessages;
};

type FormFieldLayoutChildren = {
    ref: React.RefObject<any>;
    id: string;
    events: FormValidationEvents;
};

type FormFieldLayoutProps = BaseFormFieldProps & {
    children: (p: FormFieldLayoutChildren) => React.ReactNode;
};

/**
 * FormFieldLayout is the base of all custom form elements.
 * It contains the styling and layout for the normal and alternative (alt) version of the form elements used in the CMND application.
 * @param param0
 */
export const FormFieldLayout = ({
    children,
    label,
    sublabel,
    className,
    minimal = false,
    alt,
    hideLabel,
    validationMessages,
}: FormFieldLayoutProps) => {
    const ref = useRef<any>(null);
    const id = useUID();

    const { isInvalid, errorMessage, events } = useFormValidation(
        ref,
        validationMessages
    );

    return (
        <div
            className={classNames(styles.root, className, {
                [styles.rootMinimal]: minimal,
                [styles.rootBig]: alt,
                [styles.rootError]: isInvalid,
            })}
        >
            <label
                htmlFor={id}
                className={classNames(styles.label, {
                    'visually-hidden': hideLabel,
                })}
                data-testid="form-field-label"
            >
                {label}
            </label>
            {sublabel && <span className={styles.sublabel}>{sublabel}</span>}
            <div className={styles.inputWrap}>
                {children({ ref, id, events })}
            </div>
            <ErrorMessage message={errorMessage} />
        </div>
    );
};

export type TextFieldProps = {
    name?: string;
    value: string;
    placeholder?: string;
    type?: 'email' | 'password' | 'text' | 'search' | 'number' | 'tel';
    showPasswordToggle?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    autoComplete?: 'on' | 'off';
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    required?: boolean;
} & BaseFormFieldProps;

export const TextField = (props: TextFieldProps) => {
    const {
        name,
        type = 'text',
        showPasswordToggle,
        placeholder = '',
        disabled = false,
        value = '',
        onChange,
        autoComplete = 'off',
        maxLength,
        minLength,
        required,
        pattern,
    } = props;

    //const { t } = useLocaleContext();
    const { t, i18n } = useTranslation();
    const [readable, setReadable] = useState(type !== 'password');

    let newType = 'text';
    if (type === 'password' && !readable) {
        newType = 'password';
    }

    let extraProps = {};
    if (type === 'number') {
        extraProps = {
            pattern: '[0-9]*',
            inputMode: 'numeric',
        };
    }

    return (
        <FormFieldLayout {...props}>
            {({ ref, id, events }) => {
                return (
                    <>
                        {type === 'search' && (
                            <Icon
                                title={t`Search`}
                                className={classNames(
                                    styles.iconSearch,
                                    styles.icon
                                )}
                                size="XL"
                                icon={iconSearch}
                            />
                        )}
                        <input
                            data-testid="form-field-input"
                            disabled={disabled}
                            placeholder={placeholder}
                            className={classNames({
                                [styles.searchInput]: type === 'search',
                                [styles.passwordInput]: type === 'password',
                            })}
                            ref={ref}
                            id={id}
                            name={name}
                            value={value}
                            type={type === 'password' ? newType : type}
                            onChange={e => {
                                events.onChange(e);
                                onChange(e);
                            }}
                            onKeyDown={e => {
                                // React has some strange behaviour for whitespace inside of a textfield. Leading white space will make the cursor jump to the start of the text.
                                // This fix just prevents all white space from typed in a email field. However you can still paste white-space between words (this does not seem to be a big issue).
                                if (type === 'email' && e.key.match(/\s/)) {
                                    e.preventDefault();
                                }
                            }}
                            onInvalid={events.onInvalid}
                            autoComplete={autoComplete}
                            required={required}
                            minLength={minLength}
                            maxLength={maxLength}
                            pattern={pattern}
                            {...extraProps}
                        />
                        {type === 'password' && showPasswordToggle && (
                            <IconButton
                                title={t`Show/hide password`}
                                className={styles.icon}
                                iconSize="XL"
                                icon={readable ? iconEyeHide : iconEyeShow}
                                onClick={() => {
                                    setReadable(!readable);
                                }}
                            />
                        )}
                    </>
                );
            }}
        </FormFieldLayout>
    );
};

export default TextField;

type ErrorMessageProps = {
    message?: string;
};

const ErrorMessage = ({ message }: ErrorMessageProps) => (
    <p
        role="alert"
        hidden={!message}
        data-testid="error-message"
        className={styles.fieldErrorMessage}
    >
        {message}
    </p>
);

type FormGroupProps = {
    label: React.ReactNode;
    hideLabel?: boolean;
    children: React.ReactNode;
    disabled?: boolean;
    className?: string;
    errorMessage?: string;
};

export const FormGroup = ({
    label,
    hideLabel,
    disabled,
    errorMessage,
    className,
    children,
}: FormGroupProps) => {
    return (
        <fieldset
            disabled={disabled}
            className={classNames(styles.group, className)}
        >
            <legend
                className={classNames(styles.label, {
                    'visually-hidden': hideLabel,
                })}
            >
                {label}
            </legend>
            {children}
            <ErrorMessage message={errorMessage} />
        </fieldset>
    );
};
