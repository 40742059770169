import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { t, gettext, ngettext, msgid, useLocale as setLocaleTtag } from 'ttag';
import useInitialRender from '@/hooks/use-initial-render';

const DEFAULT_LOCALE = 'en';

export type LocaleContextValue = {
    setLocale: (locale: string) => void;
    locale: string;
    t: typeof t;
    msgid: typeof msgid;
    gettext: typeof gettext;
    ngettext: typeof ngettext;
};

const defaultLocaleContext = {
    setLocale: () => {},
    locale: DEFAULT_LOCALE,
    t,
    msgid,
    gettext,
    ngettext,
};

export const LocaleContext = React.createContext<LocaleContextValue>(
    defaultLocaleContext
);

type Props = {
    defaultLocale?: string;
    children: React.ReactNode;
};

const LocaleProvider = ({
    defaultLocale = DEFAULT_LOCALE,
    children,
}: Props) => {
    const [locale, setLocaleState] = useState(defaultLocale);

    /**
     * We need to match the ttag locale with the default locale BEFORE rendering and we only need to this on initial render.
     */
    const isInitialRender = useInitialRender();
    if (isInitialRender) {
        setLocaleTtag(defaultLocale);
    }

    /**
     * Update the html lang attribute
     */
    useEffect(() => {
        document.documentElement.lang = locale;
    }, [locale]);

    /**
     * This callback sets both the locale in ttag and in the state
     */
    const setLocale = useCallback((newLocale: string) => {
        setLocaleTtag(newLocale);
        setLocaleState(newLocale);
    }, []);

    const contextValue = useMemo<LocaleContextValue>(() => {
        return {
            setLocale,
            gettext,
            ngettext,
            locale,
            msgid,
            t,
        };
    }, [locale, setLocale]);

    return (
        <LocaleContext.Provider value={contextValue}>
            {children}
        </LocaleContext.Provider>
    );
};

export default LocaleProvider;
