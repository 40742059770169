import React, { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './request-registration-modal.css';
import Modal from '@/components/modal';
import { ModalActions } from '@/components/modal/modal';
import TextField from '@/components/form-field';
import Button from '@/components/button';
import RegistrationImage from '@/assets/images/illustration_mail.svg?no-sprite';
import {
    SendRegistrationMailMutationVariables,
    useSendPasswordRecoveryEmailMutation,
} from '@/graphql/generated';

import getGraphlErrorCode from '@/util/get-graphql-error-code';
import useToastContext from '@/hooks/use-toast-context';

type Props = {
    loading: boolean | undefined;
    show: boolean;
    showConfirmation: boolean;
    onHide: VoidFunction;
    formError: string | undefined;
    setFormError: React.Dispatch<React.SetStateAction<string>>;
    handleSubmit: (event: any) => void;
};

const RequestRegistrationModal = ({
    loading,
    show,
    onHide,
    handleSubmit,
    formError,
    setFormError,
    showConfirmation,
}: Props) => {
    // const { t } = useLocaleContext();
    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState<
        SendRegistrationMailMutationVariables
    >({
        email: '',
    });
    const { triggerToast } = useToastContext();
    const [requestFormError, setRequestFormError] = useState('');
    const requestErrorToText: TranslationMap<RequestRegistrationErrors> = {
        USER_DOES_NOT_EXISTS: msg => t`notRecogniseAccount`,
        ERROR_USER_EMAIL_WRONG_FORMAT: msg => t`errorEmail`,
        UNKNOWN: msg => t`somethingWrongRetry`,
    };

    const [sendMail, setSendMail] = useState<boolean>(false);

    const [
        sendPasswordRecoveryEmail,
        { loading: loadingPasswordRecovery },
    ] = useSendPasswordRecoveryEmailMutation();

    function doSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (event.currentTarget.reportValidity()) {
            handleSubmit(formData);
        }
    }

    function doHide() {
        setFormData({
            email: '',
        });
        onHide();
    }

    function doSendMail() {
        setSendMail(true);
        sendPasswordRecoveryEmail({
            variables: {
                email: formData.email,
                language: navigator.language,
            },
        })
            .then(() => {
                // setShowRegistrationConfirmation(true);
                setSendMail(false);
                triggerToast({
                    message: t`mailHaveSend`,
                    type: 'success',
                });
            })
            .catch(e => {
                setSendMail(false);
                const code = getGraphlErrorCode(
                    e,
                    requestErrorToText
                ) as RequestRegistrationErrors;
                setRequestFormError(requestErrorToText[code](t));
            });
    }

    return (
        <>
            {!showConfirmation && (
                <Modal
                    title={t`forgetYourPassword`}
                    show={show}
                    onHide={doHide}
                    className={styles.modalDark}
                >
                    <>
                        <p
                            className={styles.playlistIntro}
                        >{t`sendLinkByMail`}</p>

                        <form
                            className={styles.form}
                            data-testid="request-registration-form"
                            onSubmit={doSubmit}
                        >
                            <img
                                data-testid="empty-image"
                                src={RegistrationImage}
                                alt=""
                                className={styles.modalImage}
                            />
                            <TextField
                                label={t`email`}
                                placeholder={t`entryMail`}
                                type="email"
                                required
                                validationMessages={{
                                    valueMissing: t`missingMail`,
                                    typeMismatch: t`incorrectEmail`,
                                    customError: formError || undefined,
                                }}
                                value={formData.email}
                                alt
                                onChange={event => {
                                    setFormError('');
                                    setFormData({
                                        email: event.target.value,
                                    });
                                }}
                            />

                            <ModalActions>
                                <Button
                                    big
                                    loading={sendMail}
                                    onClick={doSendMail}
                                    // type="submit"
                                >{t`updatePassword`}</Button>
                            </ModalActions>
                        </form>
                    </>
                </Modal>
            )}
            {showConfirmation && (
                <Modal title={t`mailHaveSend`} show={show} onHide={doHide}>
                    <>
                        <p
                            className={styles.playlistIntro}
                        >{t`sendChanePasswordMail`}</p>

                        <ModalActions>
                            <Button
                                big
                                loading={loading}
                                onClick={doHide}
                            >{t`done`}</Button>
                        </ModalActions>
                    </>
                </Modal>
            )}
        </>
    );
};

export default RequestRegistrationModal;
