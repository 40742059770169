/* eslint-disable no-param-reassign */

import { useLayoutEffect, useRef } from 'react';

type Orientation = 'vertical' | 'horizontal';
type Alignment = 'start' | 'end';

type Options = {
    /** Duration in milliseconds */
    duration: number;

    /** Whether we also should animate the height of the surrounding element */
    animateHeight?: boolean;

    /** Orientation of the list. Are items laid out horizontally or vertically? */
    orientation?: Orientation;

    /** Does adding an item cause other items to be pushed down (start) or pushed up (end)? */
    align?: Alignment;
};

const defaultOptions: Options = {
    duration: 300,
    animateHeight: false,
    orientation: 'vertical',
    align: 'start',
};

/**
 * The cache item is used to hold information about each element in the list
 */
type CacheItem = {
    frame?: number;
    timeout?: number;
    positionX: number;
    positionY: number;
};

function isHTMLElement(node: any): node is HTMLElement {
    return node instanceof Node && node.nodeType === node.ELEMENT_NODE;
}

function triggerReflow(node: Node) {
    if (isHTMLElement(node)) {
        return node.offsetTop;
    }
    return 0;
}

/**
 * Event callback which is used to reset the transition
 * @param e
 */
function moveTransitionEnd(e: TransitionEvent) {
    if (e.propertyName === 'transform') {
        (e.currentTarget as HTMLElement).style.transition = '';
    }
}

function heightTransitionEnd(e: TransitionEvent) {
    if (e.propertyName === 'height') {
        (e.currentTarget as HTMLElement).style.transition = '';
        (e.currentTarget as HTMLElement).style.height = '';
    }
}

const clearDelayedActions = (node: Node, cacheItem: CacheItem) => {
    if (cacheItem.frame) {
        cancelAnimationFrame(cacheItem.frame);
        cacheItem.frame = undefined;
    }
    (node as HTMLElement).removeEventListener(
        'transitionend',
        moveTransitionEnd,
        false
    );
};

/**
 * Implements a animation which animates the addition and removal of elements in a list (by shifting the surrounding elements)
 * @param ref
 * @param options
 */
const useListAnimation = (
    ref: React.RefObject<HTMLDivElement>,
    options: Options = defaultOptions
) => {
    const cachedHeight = useRef<number | undefined>(undefined);

    useLayoutEffect(() => {
        const cache: Map<HTMLElement, CacheItem> = new Map();

        const container = ref.current;

        const createTransform = (
            cacheItem: CacheItem,
            newX: number,
            newY: number
        ) => {
            if (options.orientation === 'horizontal') {
                return `translateX(${cacheItem.positionX - newX}px)`;
            }
            return `translateY(${cacheItem.positionY - newY}px)`;
        };

        /**
         * Animate the element from its old position to its new position.
         * Old position is retrieved from cache
         * @param el
         */
        const animate = (el: HTMLElement) => {
            const cacheItem = cache.get(el);

            if (!cacheItem) {
                return;
            }
            const bounds = el.getBoundingClientRect();
            const newLeft = bounds.left;
            const newTop = bounds.top;

            /* position did not change. Skip this element */
            if (
                newLeft === cacheItem.positionX &&
                newTop === cacheItem.positionY
            ) {
                return;
            }

            /* cancel previous animation */
            clearDelayedActions(el, cacheItem);

            /*
                We use the FLIP method to:
                1. move the element to its old position (we basicly revert the add/remove).
                2. Give the browser time to update and then remove the position and apply a transition
                3. The element will now animate to its new position
                4. After a timeout we will remove the transition
            */
            el.style.transition = 'none';
            el.style.transform = createTransform(cacheItem, newLeft, newTop);

            cacheItem.frame = window.requestAnimationFrame(() => {
                triggerReflow(el);
                el.style.transform = '';
                el.style.transition = `transform ${options.duration}ms ease`;
                el.addEventListener('transitionend', moveTransitionEnd);
            });

            cacheItem.positionX = newLeft;
            cacheItem.positionY = newTop;
        };

        const animateHeight = (el: HTMLElement) => {
            const newHeight = el.offsetHeight;
            el.style.transition = 'none';
            el.style.height = `${cachedHeight.current!}px`;
            window.requestAnimationFrame(() => {
                el.style.height = `${newHeight}px`;
                el.style.transition = `height ${options.duration}ms ease`;
                el.addEventListener(
                    'transitionend',
                    heightTransitionEnd,
                    false
                );
                cachedHeight.current = newHeight;
            });
        };

        const deleteNodeCache = (node: Node) => {
            if (isHTMLElement(node)) {
                cache.delete(node);
            }
        };

        const initializeNodeCache = (node: Node) => {
            if (isHTMLElement(node)) {
                const bounds = node.getBoundingClientRect();
                cache.set(node, {
                    frame: undefined,
                    timeout: undefined,
                    positionY: bounds.top,
                    positionX: bounds.left,
                });
            }
        };

        const observer = new MutationObserver(records => {
            const children = Array.from(container!.childNodes).filter(node =>
                isHTMLElement(node)
            ) as HTMLElement[];
            let elementAfterAddedOrRemoved: HTMLElement | undefined;
            let elementBeforeAddedOrRemoved: HTMLElement | undefined;

            records.forEach(record => {
                // do cleanup and initialization
                record.addedNodes.forEach(initializeNodeCache);
                record.removedNodes.forEach(deleteNodeCache);

                // hmmm nextSibling seems to point to the element after the addedNodes. Which is just what i need :-D
                if (children.includes(record.nextSibling as HTMLElement)) {
                    elementAfterAddedOrRemoved = record.nextSibling as HTMLElement;
                }

                if (children.includes(record.previousSibling as HTMLElement)) {
                    elementBeforeAddedOrRemoved = record.previousSibling as HTMLElement;
                }
            });

            /**
             * Removing or adding an element only affects the position of the elements after that element (or before when the alignment is 'end').
             * We can use that as a tiny optimalisation to only animate those elements.
             */
            if (options.align === 'end' && elementBeforeAddedOrRemoved) {
                // animate height
                if (options.animateHeight) {
                    animateHeight(ref.current!);
                }

                let el: HTMLElement | null = elementBeforeAddedOrRemoved;
                while (el) {
                    animate(el);
                    el = el.previousElementSibling as HTMLElement;
                }
            } else if (elementAfterAddedOrRemoved) {
                // animate height
                if (options.animateHeight) {
                    animateHeight(ref.current!);
                }

                let el: HTMLElement | null = elementAfterAddedOrRemoved;
                while (el) {
                    animate(el);
                    el = el.nextElementSibling as HTMLElement;
                }
            }
        });

        if (container) {
            if (options.animateHeight) {
                cachedHeight.current = container.offsetHeight;
            }

            // after mount we should initialize the child elements
            Array.from(container.childNodes).forEach(initializeNodeCache);

            // initialize the mutation observer to only listen to child list mutations
            observer.observe(container, {
                childList: true,
            });
        }

        return () => {
            if (container) {
                container.removeEventListener(
                    'transitionend',
                    heightTransitionEnd,
                    false
                );
            }
            observer.disconnect();
            Array.from(cache.entries()).forEach(entry =>
                clearDelayedActions(...entry)
            );
        };
    }, [
        options.align,
        options.animateHeight,
        options.duration,
        options.orientation,
        ref,
    ]);
};

export default useListAnimation;
