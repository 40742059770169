import { useState, useMemo, useLayoutEffect, useCallback } from 'react';

/**
 * All possible invalid states
 */
type PossibleInvalidStates = Omit<ValidityState, 'valid'>;

/*
 * All possible invalid states again but listed in a array (we somehow can't loop over the keys in the html5 validity object).
 */
const invalidStates: (keyof PossibleInvalidStates)[] = [
    'badInput',
    'customError',
    'patternMismatch',
    'tooLong',
    'tooShort',
    'typeMismatch',
    'valueMissing',
    'rangeOverflow',
    'rangeUnderflow',
];

export type ValidationMessages = {
    [K in keyof PossibleInvalidStates]?: string;
};

type ValidInputElement =
    | HTMLInputElement
    | HTMLSelectElement
    | HTMLTextAreaElement;

const isValidInputElement = (
    el: HTMLElement | null
): el is ValidInputElement => {
    return (
        el instanceof HTMLInputElement ||
        el instanceof HTMLSelectElement ||
        el instanceof HTMLTextAreaElement
    );
};

type State = {
    isInvalid: boolean;
    errorMessage?: string;
};

const defaultState: State = {
    isInvalid: false,
    errorMessage: undefined,
};

export type FormValidationEvents = {
    onChange: (e: React.ChangeEvent) => void;
    onInvalid: (e: React.FormEvent) => void;
};

/**
 * This hook allows you to use HTML5 form validation in your react components.
 * It is mostly used in the form-field component but is generic enough to be used in other components (as long as you pass a valid html5 input element)
 * @param ref
 * @param validationMessages
 */
const useFormValidation = (
    ref: React.RefObject<ValidInputElement>,
    validationMessages?: ValidationMessages
) => {
    const [validationState, setValidationState] = useState<State>(defaultState);

    const customError = validationMessages
        ? validationMessages.customError
        : null;

    /**
     * When a invalid event is triggered we prevent the default popup from being shown (which is ugly).
     * Then we figure out which states for the input are invalid and optionally the message that has to be shown for that state
     *
     * @param e
     */
    const onInvalid = useCallback(
        (e: React.FormEvent) => {
            if (!validationMessages) {
                return;
            }

            e.preventDefault();

            let newErrorMessage: string | undefined;

            const hasInvalidFields = invalidStates.some(key => {
                const invalidState = (e.currentTarget as ValidInputElement)
                    .validity[key];
                const validityMessage = validationMessages[key];
                if (invalidState) {
                    if (validityMessage) {
                        newErrorMessage = validityMessage;
                    }
                    return true;
                }
                return false;
            });

            setValidationState({
                isInvalid: hasInvalidFields,
                errorMessage: newErrorMessage,
            });
        },
        [validationMessages]
    );

    /**
     * If the user corrects his/her error then we immediately reset the error state
     */
    const onChange = useCallback((e: React.ChangeEvent) => {
        const target = e.currentTarget as ValidInputElement;
        setValidationState(oldValidationState => {
            if (oldValidationState.isInvalid && target.validity.valid) {
                return defaultState;
            }
            return oldValidationState;
        });
    }, []);

    /**
     * You can also use a custom error
     */
    useLayoutEffect(() => {
        if (isValidInputElement(ref.current)) {
            if (!customError) {
                ref.current.setCustomValidity('');
                setValidationState(defaultState);
            } else if (customError) {
                ref.current.setCustomValidity(customError);
                ref.current.reportValidity();
            }
        }
    }, [ref, customError]);

    const events: FormValidationEvents = useMemo(() => {
        return {
            onChange,
            onInvalid,
        };
    }, [onChange, onInvalid]);

    return {
        ...validationState,
        events,
    };
};

export default useFormValidation;
