export const initialState = {
    menuExpanded: false,
    navShown: false,
    notificationsShown: false,
    upgradeShown: false,
    rechargeShown: false,
    hasUnreadNotifications: false,
    supperUser: false,
    roomId: '',
    searchDeviceId: '',
};

export type GlobalState = typeof initialState;
