import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enUsTrans from './en-us/en-us.json';
import zhCnTrans from './zh-cn/zh-cn.json';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            enUs: {
                translation: enUsTrans,
            },
            zhCn: {
                translation: zhCnTrans,
            },
        },
        fallbackLng: 'enUs',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });
