/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/camelcase */

export type EventParams = {
    event_category?: string;
    event_label?: string;
    event_action?: string;
    value?: string;
};

declare global {
    interface Window {
        dataLayer: any[]; /* eslint-disable-line */
        gtag(type: 'js', date: Date): void;
        gtag(type: 'event', event: string, params?: EventParams): void;
        gtag(type: 'set', values: { [key: string]: any }): void;
        gtag(type: 'config', value: any): void;
        gtag(type: 'config', id: string, values: { [key: string]: any }): void;
    }
}

/**
 * If GA_ID is not defined or GA_ID === 'false' then we don't include ga setup code in the head of the document
 * If GA_ID === 'false' we use a fake gtag function. This can be used in preview or development environments.
 * If GA_ID is not defined we assume that it was not set so we display a warning.
 */
function ensureSetup() {
    // early abort when setup was already done
    if (typeof window.gtag !== 'undefined') {
        return;
    }

    if (!process.env.GA_ID) {
        console.warn('Google Analytics is not correctly configured!. Please set GA_ID to a working Google Analytics Account ID or to "false" for debugging'); // eslint-disable-line
        return;
    }

    window.gtag = function gtag(...args: any[]) {
        const values = args.map(arg => JSON.stringify(arg)).join(' ');

        // eslint-disable-next-line no-console
        console.info(
            `%c Google analytics: ${values} `,
            'background: rgba(240,220,0, .3); color: #444'
        );
    };
}

export function trackPageView(page: string) {
    ensureSetup();
    window.gtag('config', process.env.GA_ID || '', {
        page_path: page,
    });
}

export function trackEvent(event: string, params?: EventParams) {
    ensureSetup();
    if (params) {
        window.gtag('event', event, params);
    } else {
        window.gtag('event', event);
    }
}

export function trackSet(values: EventParams) {
    ensureSetup();
    window.gtag('set', values);
}
