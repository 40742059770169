import { DEPLOY_ENV } from './environment';

let sentryReady = false;

const loadSentry = (function sentryLoader() {
    let sentryLoadPromise: Promise<typeof import('@sentry/browser')>;

    async function loadAndSetupSentry() {
        const sentry = await import(
            /* webpackChunkName: "sentry" */ '@sentry/browser'
        );

        const { init, configureScope } = sentry;

        init({
            dsn: process.env.SENTRY_DSN,
            environment: DEPLOY_ENV,
            release: process.env.APP_VERSION || 'cmnd',
        });

        configureScope(scope => {
            scope.setTag('commithash', process.env.APP_COMMITHASH || 'unknown');
            scope.setTag('version', process.env.APP_VERSION || 'unknown');
        });

        return sentry;
    }

    return () => {
        if (!sentryLoadPromise) {
            sentryLoadPromise = loadAndSetupSentry();
        }
        return sentryLoadPromise;
    };
})();

/**
 * Manually logs a error. Does not do anything until initErrorLogging is called
 * @param error
 * @param extras
 */
export async function logError(error: object, extras?: object) {
    // only log errors when sentry is ready
    if (!sentryReady) {
        return;
    }

    const { captureException, withScope } = await loadSentry();

    withScope(scope => {
        if (extras) {
            scope.setExtras(extras);
        }
        captureException(error);
    });
}

/**
 * Setup error logging by hijacking window.onerror.
 * Because the sentry library is really big we lazy load / initialize it after the first error.
 */
export default function initErrorLogging() {
    if (process.env.NODE_ENV !== 'production') {
        return;
    }

    if (!process.env.SENTRY_DSN || process.env.SENTRY_DSN === 'false') {
        console.warn('Sentry is not (correctly) configured'); // eslint-disable-line no-console
        return;
    }

    window.onerror = async (message, url, lineNo, columnNo, errorObj) => {
        // we remove the current onerror function so that sentry will replace it with its own version
        (window as any).onerror = null; // eslint-disable-line @typescript-eslint/no-explicit-any

        await loadSentry();

        if (window.onerror) {
            window.onerror(message, url, lineNo, columnNo, errorObj);
        }
    };

    window.onunhandledrejection = async (event: PromiseRejectionEvent) => {
        // we remove the current onunhandledrejection function so that sentry will replace it with its own version
        (window as any).onunhandledrejection = null; // eslint-disable-line @typescript-eslint/no-explicit-any

        await loadSentry();

        if (window.onunhandledrejection) {
            window.onunhandledrejection(event);
        }
    };

    sentryReady = true;
}
