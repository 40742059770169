import React from 'react';
import classNames from 'classnames';
import styles from './loader.css';

type Props = {
    className?: string;
};

const Loader = ({ className }: Props) => {
    return (
        <div
            className={classNames(styles.root, className)}
            data-testid="loader"
        >
            <span className={classNames(styles.dot, styles.dot1)} />
            <span className={classNames(styles.dot, styles.dot2)} />
            <span className={classNames(styles.dot, styles.dot3)} />
            <span className={classNames(styles.dot, styles.dot4)} />
        </div>
    );
};

export default Loader;
