import React, { useMemo, useContext, useLayoutEffect } from 'react';
import { useUID } from 'react-uid';
import classNames from 'classnames';
import useKeyListener from '@/hooks/use-key-listener';
import styles from './modal.css';
import { ModalManagerContext } from './modal-manager';
import Content from './modal-content';

export type ModalProps = {
    children: React.ReactNode;
    title: string;
    show: boolean;
    onHide?: () => void;
    initialFocusRef?: React.RefObject<HTMLElement>;
    className?: string;
    type?: 'detailed' | 'plain';
};

const Modal = ({
    children,
    title,
    show,
    onHide,
    type,
    initialFocusRef,
    className,
}: ModalProps) => {
    const [, dispatch] = useContext(ModalManagerContext);
    const id = useUID();

    useKeyListener(
        'Escape',
        () => {
            if (onHide) {
                onHide();
            }
        },
        { ignoreInputEvents: false }
    );

    const component = useMemo(() => {
        return (
            <Content
                onHide={onHide}
                title={title}
                initialFocusRef={initialFocusRef}
                className={className}
                type={type}
            >
                {children}
            </Content>
        );
    }, [children, className, initialFocusRef, onHide, title, type]);

    useLayoutEffect(() => {
        if (!show) {
            return dispatch({ type: 'unregisterModal', id });
        }

        dispatch({ type: 'registerModal', id, component });

        return () => {
            dispatch({ type: 'unregisterModal', id });
        };
    }, [component, dispatch, id, show]);

    return null;
};

export default Modal;

type ModalActions = {
    children: React.ReactNode;
    centered?: boolean;
};

export const ModalActions = ({ children, centered }: ModalActions) => {
    return (
        <footer
            className={classNames(styles.actions, {
                [styles.actionsCentered]: centered,
            })}
        >
            {children}
        </footer>
    );
};
