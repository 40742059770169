import { useEffect, useState, useMemo } from 'react';

const breakpoints = {
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width: 899px)',
    large: '(min-width: 900px)',
};

/**
 * The use-media-query listens to changes in media query states using the matchMedia api.
 * @param breakpoint
 */
const useMediaQuery = (breakpoint: keyof typeof breakpoints) => {
    const listener = useMemo(() => window.matchMedia(breakpoints[breakpoint]), [
        breakpoint,
    ]);
    const [matches, setMatches] = useState(listener.matches);

    useEffect(() => {
        const callback = (event: MediaQueryListEvent) => {
            setMatches(event.matches);
        };

        listener.addListener(callback);

        return () => {
            listener.removeListener(callback);
        };
    }, [listener]);

    return matches;
};

export default useMediaQuery;
