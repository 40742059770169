/**
 * This file provides overrides for the buildin apollo hooks.
 * This is possible because graphql-code-generator provides us with an option to specify the import location: "apolloReactHooksImportFrom" (https://graphql-code-generator.com/docs/generated-config/typescript-react-apollo#apolloreacthooksimportfrom-string-default-value-apollo-react-hooks)
 *
 * Currently we only use it to disable polling on the preview server
 */

/* eslint-disable no-param-reassign */

import {
    useQuery as useApolloQuery,
    QueryHookOptions as ApolloQueryHookOptions,
} from '@apollo/react-hooks';
import { OperationVariables, SubscribeToMoreOptions } from 'apollo-client';
import { DocumentNode } from 'graphql';
import { useEffect } from 'react';
import { DEPLOY_ENV, IS_E2E } from '@/util/environment';

export * from '@apollo/react-hooks';

/**
 * Custom QueryHookOptions which adds 'subscribeToMore' option to the options of useQuery
 * Normally this function is only returned in the result. e.g. :
 *
 * const result = useQuery(...);
 *
 * //now you can call subscribeToMore!
 * result.subscribeToMore()
 *
 * But....subscribeToMore will be now be called on every render :-( (which is probably not want you want)
 * To fix this we need to wrap the subscribeToMore call in a 'useEffect' e.g. :
 *
 * useEffect(() => {
 *  result.subscribeToMore() // this will now only be called once!
 * }, []);
 *
 * The subscribeToMore option is used to automate this behaviour
 *
 */
export type QueryHookOptions<D, V> = ApolloQueryHookOptions<D, V> & {
    subscribeToMore?: SubscribeToMoreOptions<D, V>;
};

export const useQuery = <TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: QueryHookOptions<TData, TVariables>
) => {
    if (options) {
        // Lets disable polling on Netlify or in the e2e environment
        if (DEPLOY_ENV === 'preview' || IS_E2E) {
            options.pollInterval = undefined;
        }
    }

    const result = useApolloQuery<TData, TVariables>(query, options);

    useEffect(() => {
        let unsubscribe: VoidFunction | undefined;

        if (options && options.subscribeToMore) {
            unsubscribe = result.subscribeToMore(options.subscribeToMore);
        }

        return unsubscribe;
    }, [options, result.subscribeToMore]); // eslint-disable-line react-hooks/exhaustive-deps

    return result;
};
